import React, { useState, useEffect } from "react";
import { BreadCrumbs } from "../common/layout";
import { Helmet } from "react-helmet";
import authService from "../../../src/services/authService";
import entityService from "../../services/entityService";
import { Picker, GeneralButton, TextInput } from "../common/inputs";
import { FormContainer } from "../common/containers/formContainer";
import useApi from "../../hooks/useApi";
import queryString from "query-string";
import Joi from "joi-browser";
import useForm from "../../hooks/useForm";
import { getInitials } from "../../utils/NameInitials";
import { toast } from "react-toastify";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import { Link } from "react-router";


function ProfileSettings(props) {
    const { location } = props;
    const { contact_id } = queryString.parse(location.search);
    const { constants, category } = authService.getCurrentUser();

    const [loader, setLoader] = useState(false);
    const [authLogs, setAuthLogs] = useState([]);
    const [profileValues, setProfileValues] = useState([]);

    const contactApi = useApi(entityService.getMemberByContactId);

    const fetchProfileDetails = async () => {
        try {
            setLoader(true);
            const { data } = await contactApi.request({ contact_id });
            const contactData = data.data;
            setAuthLogs(contactData.authLogs);
            setProfileValues({
                contact_id,
                first_name: contactData.first_name,
                last_name: contactData.last_name,
                phone_no: contactData.phone_no,
                organization: contactData.organization
            });
            setLoader(false);
        } catch (error) {
            toast.error("Failed to fetch profile details");
        }
    };

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    //--------------- Render Components -------------------//
    const ProfileColumn = () => (
        <div className="card">
            <div className="card-header d-sm-flex w-100  py-3 align-items-center">
                <div className="avatar avatar-2xl bg-secondary text-white  mx-auto mb-3 mb-sm-0 rounded-3xl">
                    <span>{getInitials(profileValues.first_name + ' ' + profileValues.last_name, "")}</span>
                </div>
                <div className="w-100 ml-sm-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="font-bold my-0 truncate">{profileValues.first_name + ' ' + profileValues.last_name}</h5>
                        <Link className="btn  btn-light  rounded-full mr-2 btn-closeHeader" title="close and go back to listing" to="/entities/members">
                            <i alt="close this page" class="fa fa-times mr-1"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

    const ProfileEditColumn = () => (
        <FormContainer title="Profile">
            <form className="form-horizontal">
                <TextInput
                    name="first_name"
                    label="First Name"
                    value={profileValues.first_name || ""}
                    type="vertical"
                    readonly
                />
                <TextInput
                    name="last_name"
                    label="Last Name"
                    value={profileValues.last_name || ""}
                    type="vertical"
                    readonly
                />
                <TextInput
                    name="phone_no"
                    label="Phone"
                    value={profileValues.phone_no || ""}
                    type="vertical"
                    readonly
                />
            </form>
        </FormContainer>
    );
   
    const LoginHistory = () => {

        const [visibleItems, setVisibleItems] = useState(10);

        const loadMoreItems = () => {
            setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
        };

        const formatDate = (dateString) => {
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            };
            return new Date(dateString).toLocaleString('en-US', options);
        };
        return (
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Login History</h5>  
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table class="table text-secondary">
                            <tbody>
                            <tr className="stickyRow bg-light">
                                <th>Time </th>
                                <th>Device</th>
                                <th>OS</th>
                                <th>IP</th>
                            </tr>
                                {authLogs.slice(0, visibleItems).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{formatDate(item.created_at)}</td>
                                            <td>{item.device_type}</td>
                                            <td>{item.os}</td>
                                            <td>{item.ip_address}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="row d-flex justify-content-center">
                    {visibleItems < authLogs.length && (
                        <button onClick={loadMoreItems} className="btn btn-outline-secondary">Load More</button>
                    )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {loader == true && <LoadingSpinner />}
            <Helmet>
                <title>Profile Settings | {global.config.title}</title>
            </Helmet>
            <BreadCrumbs Header="Profile Settings" activeModule="Profile Settings" 
                Route1="/dashboard"
                Label1="Dashboard"
                Route2="/entities/members"
                Label2="All Team Members" /> <br />

            <div className="row">
                <div className="col-lg-12">
                    <ProfileColumn />
                </div>
                <div className="col-lg-12">
                    <ProfileEditColumn />
                </div>
                <div className="col-lg-12">
                    {authLogs && <LoginHistory /> }
                </div>
            </div>
        </>
    );
}

export default ProfileSettings;
