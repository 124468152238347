import React, { useState, useEffect, useCallback } from "react";
import { BreadCrumbs } from "../../../common/layout";
import { Helmet } from "react-helmet";
import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";
import { Picker, GeneralButton, TextInput } from "../../../common/inputs";
import { FormContainer } from "../../../common/containers/formContainer";
import useApi from "../../../../hooks/useApi";
import Joi from "joi-browser";
import useForm from "../../../../hooks/useForm";
import { getInitials } from "../../../../utils/NameInitials";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { Link } from "react-router";
import coreService from "../../../../services/coreService";
import { Modal } from "react-bootstrap";
import { browserHistory } from "react-router";
function ProfileSettings(props) {
  // Retrieve the current user's contact ID
  const { contact_id } = authService.getCurrentUser();

  // State variables
  const [loader, setLoader] = useState(false);
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);
  const [verificationOTP, setVerificationOTP] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showCorrectionModal, setShowCorrectionModal] = useState(false);
  const [profileValues, setProfileValues] = useState({});
  const [qrCodeModal, setQrCodeModal] = useState(false);

  // State variable to store the previous MFA method
  const [previousMfaMethod, setPreviousMfaMethod] = useState("");

  // Validation schema for settings form
  const settingsSchema = Joi.object()
    .keys({
      default_mfa: Joi.string().required().label("MFA Type"),
    })
    .unknown(true);

  // MFA types for the settings picker
  const mfaTypes = [
    { label: "OTP", value: "otp" },
    { label: "Authenticator", value: "authenticator" },
  ];

  // API hooks
  const contactApi = useApi(entityService.getMemberByContactId);
  const updateContactApi = useApi(entityService.updateEntityContact);
  const verifyReqApi = useApi(coreService.initiateNumberVerification);
  const validateReqApi = useApi(coreService.validatePhoneNumber);
  const profileCorrectionApi = useApi(coreService.requestProfileCorrection);
  const generateQrCodeApi = useApi(authService.requestQrCode);

  // Derived state variables for QR code generation status
  const isSuccess = generateQrCodeApi?.data?.success;
  const isLoading = generateQrCodeApi.loading;
  const isError =
    generateQrCodeApi?.data?.success === false || generateQrCodeApi.error;
  const clearQrData = () => {
    generateQrCodeApi.setData(null);
  };

  // Function to update settings
  const updateSettings = async (mfa_method = null) => {
    setIsUpdatingSettings(true);
    mfa_method = mfa_method ?? settingsValues.default_mfa;
    try {
      const { data } = await updateContactApi.request({
        default_mfa: mfa_method,
        contact_id,
      });
      if (data.success) {
        toast.success(
          `Default MFA has been successfully set as ${
            mfa_method.charAt(0).toUpperCase() + mfa_method.slice(1)
          }`
        );
        fetchProfileDetails();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating the settings.");
    } finally {
      setIsUpdatingSettings(false);
    }
  };

  // Function to generate QR code
  const generateQRCode = useCallback(async () => {
    const response = await generateQrCodeApi.request();
    if (response?.data?.success) updateSettings("authenticator");
  }, [generateQrCodeApi]);

  // Function to handle closing the QR code modal
  const handleCloseQrCodeModal = () => {
    setQrCodeModal(false);
    if (!isSuccess) {
      handlePickerChange("default_mfa", previousMfaMethod || "otp"); // Revert to previous MFA method
    }
    clearQrData();
  };

  // Initiate phone number verification
  const initiateVerification = async () => {
    try {
      setVerificationOTP("");
      setOtpErrorMsg(null);
      const response = await verifyReqApi.request();
      if (response.data?.success) {
        setShowOtpModal(true);
      } else {
        toast.error(
          response.data?.message ||
            "Something went wrong while processing your request."
        );
      }
    } catch (error) {
      toast.error("Network error. Please try again.");
    }
  };

  // Handle OTP validation
  const handleOTPValidation = async () => {
    try {
      const response = await validateReqApi.request({ otp: verificationOTP });
      if (response.data?.success) {
        toast.success(response.data?.message);
        setShowOtpModal(false);
        fetchProfileDetails();
      } else {
        setOtpErrorMsg(response.data?.message || "Something went wrong.");
      }
    } catch (error) {
      setOtpErrorMsg("Failed to validate OTP. Please try again.");
    }
  };

  // Resend OTP
  const handleResendOTP = async () => {
    try {
      const response = await verifyReqApi.request();
      if (response.data?.success) {
        toast.success("OTP resent successfully.");
      } else {
        toast.error(response.data?.message || "Failed to resend OTP.");
      }
    } catch (error) {
      toast.error("Network error. Please try again.");
    }
  };

  // Handle profile correction request
  const handleProfileCorrectionRequest = () => {
    setShowCorrectionModal(true);
  };

  // Raise profile correction request
  const raiseProfileCorrectionRequest = async () => {
    try {
      const response = await profileCorrectionApi.request();
      if (response.data?.success) {
        toast.success(response.data?.message);
        setShowCorrectionModal(false);
      } else {
        toast.error(response.data?.message || "Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong while processing your request.");
    }
  };

  // UseForm hook for settings form
  const {
    values: settingsValues,
    handlePickerChange,
    handleSubmit: handleSettingsSubmit,
    setValues: setSettingsValues,
  } = useForm(updateSettings, settingsSchema);

  // Fetch profile details
  const fetchProfileDetails = async () => {
    setLoader(true);
    try {
      const { data } = await contactApi.request({ contact_id });
      const contactData = data.data;
      setProfileValues({
        contact_id,
        first_name: contactData.first_name,
        last_name: contactData.last_name,
        phone_no: contactData.phone_no,
        organization: contactData.organization,
        phone_verification_status: contactData.phone_verification_status,
        email: contactData.email,
      });
      setSettingsValues({ default_mfa: contactData.default_mfa });
    } catch (error) {
      // toast.error("Failed to fetch profile details");
    } finally {
      setLoader(false);
    }
  };

  // useEffect to fetch profile details on component mount
  useEffect(() => {
    fetchProfileDetails();
  }, [contact_id]);

  return (
    <>
      {/* Loading Spinner */}
      {(loader ||
        verifyReqApi.loading ||
        validateReqApi.loading ||
        profileCorrectionApi.loading ||
        updateContactApi.loading ||
        contactApi.loading) && <LoadingSpinner />}

      {/* Helmet for setting the page title */}
      <Helmet>
        <title>Profile Settings | {global.config.title}</title>
      </Helmet>

      {/* Breadcrumb navigation 
      <BreadCrumbs
        Header="Profile Settings"
        activeModule="Profile Settings"
        Route1="/dashboard"
        Label1="Dashboard"
        Route2="/entities/members"
        Label2="All Team Members"
      />*/}
      <br />

      <div className="card">
        <div className="">
          <div className="card-header">
            <Link
              className="btn btn-light rounded-full mr-2 btn-closeHeader pull-right"
              title="Close and go back to listing"
              onClick={() => browserHistory.goBack()}
            >
              <i alt="close this page" className="fa fa-times mr-1"></i>
            </Link>
          </div>
          <div className="row">
            <div className="col-8 col-lg-12 m-auto text-center " />
            <div className="col-lg-12 m-auto text-center ">
              <h3 className="font-weight-bold ">
                <div className="avatar avatar-lg bg-secondary  mx-auto mb-3 mb-sm-0 rounded-full text-white">
                  <span>
                    {getInitials(
                      `${profileValues.first_name} ${profileValues.last_name}`,
                      ""
                    )}
                  </span>
                </div>
              </h3>
              <p>
                <i className="fa fa-envelope mr-2" />
                {profileValues.email && profileValues.email}
              </p>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="card" title="Profile">
                <div className="card-header">
                  <h6>Profile</h6>
                </div>
                <div className="card-body profile-card">
                  <div className="form-horizontal">
                    <TextInput
                      name="first_name"
                      label="First Name"
                      value={profileValues.first_name || ""}
                      type="vertical"
                      readonly={true}
                    />
                    <TextInput
                      name="last_name"
                      label="Last Name"
                      value={profileValues.last_name || ""}
                      type="vertical"
                      readonly={true}
                    />
                    <TextInput
                      name="phone_no"
                      label="Phone"
                      value={profileValues.phone_no || ""}
                      type="vertical"
                      readonly={true}
                    />

                    {!contactApi.loading && (
                      <div className="row mt-5 mb-5">
                        <div className="col-lg-12">
                          <span
                            onClick={handleProfileCorrectionRequest}
                            className="label bg-danger-transparent text-danger"
                            style={{ cursor: "pointer" }}
                          >
                            Not Correct?{" "}
                            <span className="text-danger font-weight-bold">
                              Notify Us
                            </span>
                          </span>
                          {profileValues.phone_verification_status ===
                          "verified" ? (
                            <span className="label bg-primary-transparent text-primary float-right">
                              Phone number verified
                            </span>
                          ) : (
                            <GeneralButton
                              className="btn btn-primary float-right"
                              faIcon="fa fa-check"
                              name="Verify Profile"
                              onClick={initiateVerification}
                              disabled={verifyReqApi.loading}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Settings Section */}
            {/* <div className="col-lg-6">
          <FormContainer title="Settings">
            <form className="form-horizontal" onSubmit={handleSettingsSubmit}>
              <div className="form-group">
                <label>Default MFA</label>
                <Picker
                  items={mfaTypes}
                  selectedItem={mfaTypes.find(
                    (item) => item.value === settingsValues.default_mfa
                  )}
                  onSelectItem={({ value }) => {
                    setPreviousMfaMethod(settingsValues.default_mfa); // Store previous value
                    handlePickerChange("default_mfa", value);
                    if (value === 'authenticator') {
                      checkQrCodeGenerated();
                    } else {
                      setQrCodeGenerated(false);
                    }
                  }}
                />
              </div>
              <div className="row mt-5 mb-5">
                <div className="col-lg-12">
                  <GeneralButton
                    className="btn btn-primary float-right"
                    faIcon="fa fa-save"
                    name="Save"
                    disabled={
                      isUpdatingSettings ||
                      qrCodeModal ||
                      (settingsValues.default_mfa === 'authenticator' && !qrCodeGenerated)
                    }
                    title={
                      settingsValues.default_mfa === 'authenticator' && !qrCodeGenerated
                        ? 'Please generate and scan the QR code before saving.'
                        : ''
                    }
                  />
                </div>
              </div>
            </form>
          
        </div> */}

            <div className="col-lg-6">
              <div className="card" title="MFA Settings">
                <div className="card-header">
                  <h6>MFA Settings</h6>
                </div>
                <div className="card-body profile-card">
                  {!contactApi.loading && (
                    <>
                      <small className="text-danger">
                        <i className="fa fa-info-circle mr-2"></i>
                        Please select one of the available Multi-Factor
                        Authentication (MFA) options below. Please note that
                        only one option can be enabled at a time. We recommend
                        using an authenticator app for enhanced security.
                      </small>
                      <div className="alert bg-light mt-4 ">
                        <span className="font-weight-bold">
                          One Time Password(OTP)
                        </span>

                        {settingsValues.default_mfa === "otp" ? (
                          <span className="font-weight-bold text-primary pull-right">
                            Active
                          </span>
                        ) : (
                          <button
                            onClick={() => updateSettings("otp")}
                            className="btn btn-sm btn-outline-secondary pull-right"
                          >
                            Enable
                          </button>
                        )}

                        <div className="d-flex">
                          <span className="p-2">
                            {" "}
                            <i className="fa fa-mobile fa-3x mr-2"></i>
                          </span>
                          <span className="p-2">
                            Receive text message on your registered mobile
                            device each time you login to your account.
                          </span>
                        </div>
                      </div>

                      <div className="alert bg-light mb-4 pb-4 mt-4">
                        <span className="font-weight-bold">
                          Authenticator app
                        </span>

                        {settingsValues.default_mfa === "authenticator" ? (
                          <span className="font-weight-bold text-primary pull-right">
                            Active
                          </span>
                        ) : (
                          <button
                            onClick={() => {
                              setQrCodeModal(true);
                            }}
                            className="btn btn-sm btn-outline-secondary pull-right"
                          >
                            Enable
                          </button>
                        )}

                        <div className="d-flex">
                          <span className="p-2">
                            {" "}
                            <i className="fa fa-qrcode fa-3x mr-2"></i>
                          </span>
                          <span className="p-2">
                            Receive codes from an authenticator app installed on
                            your device.
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* QR Code Modal */}
      <Modal
        show={qrCodeModal}
        backdrop="static"
        onHide={handleCloseQrCodeModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <h6>Set Up Authenticator</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="alert bg-light b-dashed-2  p-5">
            <p>
              To enhance your account's security, please set up two-factor
              authentication (2FA). Follow the steps below:
            </p>
            <ol>
              <li>
                Download and open an authenticator app like Google Authenticator
                or Microsoft Authenticator.
              </li>
              <li>Tap the '+' icon in the app and select 'Scan a QR code.'</li>
              <li>Scan the QR code displayed below.</li>
              <li>
                After scanning, click <strong>Continue</strong> to complete the
                setup.
              </li>
            </ol>
            <p>
              <strong>Important:</strong> Once the QR code is displayed, it will
              not be shown again. If you confirm, Authenticator will be set as
              the default 2FA method for your profile.
            </p>
          </div>

          {/* Loading state */}
          {isLoading && (
            <div className="text-center mt-4">
              <p>Generating your QR code, please wait...</p>
              <LoadingSpinner />
            </div>
          )}

          {/* Success state */}
          {isSuccess && (
            <div className="text-center mt-4">
              <img
                className="border rounded"
                width={280}
                src={generateQrCodeApi.data.qr_code}
                alt="QR Code to set up two-factor authentication using an authenticator app"
              />
              <div className="alert alert-success mt-3">
                <p>Successfully generated the QR code!</p>
                <p>
                  Scan it using Google Authenticator or Microsoft Authenticator
                  and click 'Continue' once you're done.
                </p>
                <button
                  onClick={() => {
                    setQrCodeModal(false);
                    clearQrData();
                  }}
                  className="btn btn-primary mt-4"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Error state */}
          {isError && (
            <div className="alert alert-danger mt-3 p-4">
              {generateQrCodeApi?.data?.error_message ||
                "An error occurred while generating the QR code. Please try again."}
              <button
                onClick={generateQRCode}
                className="btn btn-secondary ml-3"
              >
                Retry
              </button>
            </div>
          )}

          {/* Button to generate QR code */}
          {!isSuccess && !isLoading && !isError && (
            <div className="text-center mt-4">
              <button
                onClick={generateQRCode}
                className="btn btn-lg btn-primary"
                aria-label="Generate QR Code"
              >
                <i className="fa fa-qrcode mr-2"></i>Generate QR Code
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* OTP Verification Modal */}
      {showOtpModal && (
        <Modal show={true} onHide={() => setShowOtpModal(false)}>
          <Modal.Body className="bg-secondary text-white p-5">
            <div className="text-center">
              <h6 className="font-weight-bold">Verify Profile</h6>
              <p>
                An OTP has been sent to your registered mobile number. Please
                enter it here to complete the verification process.
              </p>
              <TextInput
                name="otp"
                label="Please enter the OTP"
                value={verificationOTP}
                onChange={({ target }) => setVerificationOTP(target.value)}
                type="vertical"
              />
              {otpErrorMsg && (
                <p className="mt-2 mb-2 text-danger">
                  <strong>{otpErrorMsg}</strong>
                </p>
              )}
              <div className="mt-4">
                <button
                  className="btn btn-white mr-2"
                  onClick={() => setShowOtpModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleOTPValidation}
                >
                  Verify
                </button>
              </div>
              <div>
                <span
                  onClick={handleResendOTP}
                  style={{ cursor: "pointer" }}
                  className="text-primary pull-right"
                >
                  Resend OTP
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Profile Correction Request Modal */}
      {showCorrectionModal && (
        <Modal show={true} onHide={() => setShowCorrectionModal(false)}>
          <Modal.Body className="bg-secondary text-white p-5">
            <div className="text-center">
              <h6 className="font-weight-bold">
                Request to Correct Profile Information
              </h6>
              <p>
                Please confirm if you would like to raise a request to correct
                your profile information.
              </p>
              <div className="mt-4">
                <button
                  className="btn btn-white mr-2"
                  onClick={() => setShowCorrectionModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={raiseProfileCorrectionRequest}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ProfileSettings;
