import client from "./serviceClient";
import http from "./httpService";
import { apiUrl } from "../../src/environment";

// Service to fetch the list of business partners
const getEntitiesList = (country_id, categ_type) => client.post(`/reports/reports/get-partners-with-country-code`, { country_id, categ_type });
// Service to fetch the list of Cleints/Debtors
const getClientDebtors = (business_partner_guid) => client.post(`/reports/reports/fetch-client-debtor-from-partner`, { business_partner_guid });
// Service to fetch the list of countries
const getCountriesList = () => client.post(`/core/countries/get-countries-array`);
// Service to fetch the list of Entities based on selected BP and Category
const getEntitiesListByBP = (bp_guid, category_id) => client.post(`/entity/entity/get-entities-list-by-bp`, { bp_guid, category_id });
// Service to fetch the list of members of Country
const MembersList = () => client.post(`/accountant/accountant/get-members`);
// Service to fetch the list of Clients
const getClientsList = params => client.post(`/entity/entity/get-traders-list`, { ...params });
// Service to fetch the list of Corporate Clients
const getCorporateClientsList = params => client.post(`/entity/entity/get-corporate-clients-list`, { ...params });
// Service to fetch the list of Debtors
const getDebtorsList = params => client.post(`/entity/entity/get-traders-list`, { ...params });
// Service to fetch the list of Customers
const getCustomersList = params => client.post(`/entity/entity/get-traders-list`, { ...params });
// Service to fetch the list of Supplier
const getSuppliersList = params => client.post(`/entity/entity/get-traders-list`, { ...params });
// Service to send mail from finance my busness side fbc
const sendFinanceBusinessMail = params => client.post(`/core/general/send-finance-business-mail`, { ...params });
const getCorporateEntitiesList = (params) => client.post(`/reports/reports/get-partners-with-country-code`, params);

/*
* General trans Api starts
*/

// Service to fetch General Invoice transactions
const getGeneralTransaction = params => client.post(`/core/general/general-transaction-list?XDEBUG_SESSION_START=PHPSTORM`, { ...params});
// Service to fetch General Invoice transactions
const ViewGeneralTransaction = params => client.post(`/core/general/view-general-transaction?XDEBUG_SESSION_START=PHPSTORM`, { ...params});
// Service to Add/Create General Invoice transactions
const createGeneralInvoice = params => client.post(`/core/general/create-general-invoice-transaction?XDEBUG_SESSION_START=PHPSTORM`, { ...params});

// service to move general transaction
const moveGeneralTransaction  = params => client.post(`/core/general/move-general-transaction`, { ...params});

// Service to delete a general transaction
const DeleteGeneralTransaction = params => client.post(`/core/general/delete-general-transaction`, { ...params});

//service to fetch summary of general trans by supplier/customer
const GeneralPendingSummary = params => client.post(`/core/general/general-pending-summary`, { ...params });
//service to fetch all pending general transactions
const ViewPendingGeneralTransaction = params => client.post(`/core/general/view-pending-general-transaction`, { ...params });

//service to remove general transactions attachemnt
const RemoveAttachment = params => client.post(`/core/general/remove-attachment`, { ...params });

//Service to calculate general trans invoices fee
const GenaralTransCalcuateFee = params => client.post(`/core/general/genaral-trans-calcuate-fee`, { ...params });
/*
* General trans Api ends
*/

const createFmbRequest = params => client.post(`/core/general/create-fmb-request?XDEBUG_SESSION_START=PHPSTORM`, { ...params});
const getSecondaryUsersList = params => client.post(`/entity/entity/get-secondary-users-list`, { ...params});

// Service to fetch login logs
const GetLoginLogs = params => client.post(`/core/auth/get-login-logs`, { ...params});

// Service to fetch client mapped users
const GetClientMappedUsers = params => client.post(`/entity/entity/get-client-mapped-users`, { ...params});

// Service to send notification to head office
const SendNotification = params => client.post(`/core/general/send-notification`, { ...params});

// Service to fetch entries from mapping history table based on the specified params.
const getFieldsMappingHistory = params => client.post(`/core/general/get-fields-mapping-history`, { ...params});

// Service to send error log report to administrators.
const sendErrorReport = params => client.post(`/core/general/send-error-report`, { ...params});

// Service to handle email subscription.
const handleEmailSubscription = params => http.post(`${apiUrl}/core/general/handle-email-subscription`, { ...params });

// Service to save user terminology.
const saveUserTerminologies = params => client.post(`/core/general/create-user-terminology`, { ...params});

const resetUserTerminologies = () => client.post(`/core/general/reset-user-terminology`);

const profitLossTrend = params => client.post(`/externalapis/forward-ai/profit-and-loss-trend`, { ...params });

const getTotalExpense = params => client.post(`/externalapis/forward-ai/total-expense`, { ...params });

const getTotalIncome = params => client.post(`/externalapis/forward-ai/total-income`, { ...params });

const getCashFlowSummary = params => client.post(`/externalapis/forward-ai/cash-flow-summary`, { ...params });

const receivablesPayables = params => client.post(`/externalapis/forward-ai/receivables-payables`, { ...params });

const customersListByBusinessId = params => client.post(`/externalapis/forward-ai/customers-list-by-business-id`, { ...params });

const getCustomerInsights = params => client.post(`/externalapis/forward-ai/customers-insights`, { ...params });

const getSharedTransactionUsers = params => client.post(`/core/general/get-shared-transaction-users`, { ...params });

const createSharedTransaction = params => client.post(`/core/general/create-shared-transaction`, { ...params });

const createBbswRate = params => client.post(`/core/fifo-bbsw-rates/create-rate`, { ...params });

const fetchBbswRate = params => client.post(`/core/fifo-bbsw-rates/fetch-rates`, { ...params });

// facility setup fee save
const SaveFacilityFee = params => client.post(`/core/special-fee/save-facility-fee`, { ...params });

// To fetch special fee list
const FetchFeeList  = params => client.post(`/core/special-fee/fetch-fee-list`, { ...params });

// To fetch a single special fee history
const FetchFeeHistory  = params => client.post(`/core/special-fee/fetch-fee-history`, { ...params });


// To delete facility setup fee for a facility
const deleteFacilityFee  = params => client.post(`/core/special-fee/delete-facility-fee`, { ...params });

//To update payment details
const UpdatePaymentDetail  = params => client.post(`/core/special-fee/update-payment-detail`, { ...params });

//To list all payment history
const FetchFeeHistoryList  = params => client.post(`/core/special-fee/fetch-fee-history-list`, { ...params });

//To list all payment history
const AddFireBaseToken  = params => client.post(`/core/firebase/save-token`, { ...params });

// Service to fetch the list of ntroducers team members
const getIntroducersList = (country_id, categ_type) => client.post(`/reports/reports/get-introducers-members`, { country_id, categ_type });

//List all clients / prospects for HubSpot syncing
const HubSpotSyncList  = params => client.post(`/core/hubspot-sync/list`, { ...params });
const HubSpotIntroducersSyncList  = params => client.post(`/core/hubspot-sync/introducers-list`, { ...params });
const SyncHubSpot  = params => client.post(`/core/hubspot-sync/sync-with-hubspot`, { ...params });

// Service to fetch the list of Corporate Suppliers
const getCorporateSuppliersList = params => client.post(`/entity/entity/get-corporate-suppliers-list`, { ...params });

// Service to initiate phone number verification
const initiateNumberVerification = params => client.post(`/core/auth/initiate-number-validation`, { ...params });

// Service to validate the phone number
const validatePhoneNumber = params => client.post(`/core/auth/validate-phone-number`, { ...params });

// Service to raise the profile correction request
const requestProfileCorrection = params => client.post(`/core/auth/request-profile-correction`, { ...params });

export default {
    getEntitiesList,
    getClientDebtors,
    getCountriesList,
    getEntitiesListByBP,
    MembersList,
    getClientsList,
    getCorporateClientsList,
    getDebtorsList,
    getCustomersList,
    getSuppliersList,
    sendFinanceBusinessMail,
    getGeneralTransaction,
    moveGeneralTransaction,
    createGeneralInvoice,
    createFmbRequest,
    getSecondaryUsersList,
    GetLoginLogs,
    GetClientMappedUsers,
    SendNotification,
    getFieldsMappingHistory,
    ViewGeneralTransaction,
    DeleteGeneralTransaction,
    sendErrorReport,
    saveUserTerminologies,
    resetUserTerminologies,
    profitLossTrend,
    getTotalExpense,
    getTotalIncome,
    getCashFlowSummary,
    receivablesPayables,
    customersListByBusinessId,
    getCustomerInsights,
    getSharedTransactionUsers,
    createSharedTransaction,
    GeneralPendingSummary,
    ViewPendingGeneralTransaction,
    RemoveAttachment,
    GenaralTransCalcuateFee,
    createBbswRate,
    fetchBbswRate,
    SaveFacilityFee,
    FetchFeeHistory,
    FetchFeeList,
    deleteFacilityFee,
    UpdatePaymentDetail,
    FetchFeeHistoryList,
    AddFireBaseToken,
    handleEmailSubscription,
    getIntroducersList,
    getCorporateEntitiesList,
    HubSpotSyncList,
    HubSpotIntroducersSyncList,
    SyncHubSpot,
    getCorporateSuppliersList,
    initiateNumberVerification,
    validatePhoneNumber,
    requestProfileCorrection
};