import axios from "axios";
import { apiUrl } from "../../src/environment";
import { ERROR_MESSAGES } from './errorMessages';

import AxiosTokens from "./axiosTokens";
import authService from "../services/authService";
import { browserHistory } from "react-router";
import { toast } from "react-toastify";

const axToken = AxiosTokens();
let cancelToken = axToken.generateCancellationToken();
//-------- Creating an Instance for Axios -------------
var axiosInstance = axios.create({
	baseURL: apiUrl,
	/* other custom settings */
});
// ------------------------------------------------

//----------- Add a request interceptor -----------
axiosInstance.interceptors.request.use(function (config) {
	config.cancelToken = cancelToken.token;
	config.withCredentials = true;
	return config;
});
// ------------------------------------------------

// Utility functions for repetitive logic
const handleLogoutAndRedirect = (path) => {
	authService.logout();
	browserHistory.push(path);
	setTimeout(() => window.location.reload(), 2000); // Delay reload by 2 seconds
};

const handleToastError = (message) => {
	toast.error(message);
};

// Main Axios interceptor logic
axiosInstance.interceptors.response.use(null, (error) => {
	if (!error.response) {
		if (error?.message !== 'Operation cancelled due to new request')
			handleToastError(ERROR_MESSAGES.NETWORK_ERROR);
		return Promise.reject(error);
	}

	const { status } = error.response;
	const expectedError = status >= 400 && status <= 500;
	const errorData = error.response.data; // Extract the error data

	// Parse the message field if it's JSON stringified
	let customErrorMessage = null;
	if (errorData.message) {
		try {
			const parsedMessage = JSON.parse(errorData.message);
			if (parsedMessage.type === 'custom') {
				customErrorMessage = parsedMessage.message;
			}
		} catch (e) {
			// If JSON parsing fails, it’s not a custom error, so we ignore it
		}
	}

	// Custom error handling for BadRequestHttpException (status 400)
	if (status === 400) {
		let message = customErrorMessage || ERROR_MESSAGES.BAD_REQUEST;
		handleToastError(message);
		return Promise.reject(error);
	}

	// Unauthorized error (status 401)
	if (status === 401) {
		handleToastError(ERROR_MESSAGES.UNAUTHORIZED);

		if (authService.tokenExists()) {
			const userInfo = authService.getCurrentUser();
			cancelToken.cancel(); // Cancel pending requests
			const redirectPath = userInfo.constants.CATEGORY.TRADERS === userInfo.category ? "/client/login" : "/login";
			handleLogoutAndRedirect(redirectPath);
		} else {
			handleLogoutAndRedirect("/login");
		}

		return Promise.reject(error);
	}

	// Forbidden error (status 403)
	if (status === 403) {
		if (authService.tokenExists()) {
			handleToastError(ERROR_MESSAGES.FORBIDDEN);
		} else {
			handleToastError(ERROR_MESSAGES.UNAUTHORIZED);
			handleLogoutAndRedirect("/login");
		}

		return Promise.reject(error);
	}

	// For other expected errors
	if (expectedError) {
		let errorMessage = customErrorMessage || ERROR_MESSAGES.GENERIC_ERROR;
		handleToastError(errorMessage);
	}

	return Promise.reject(error);
});
// -----------------------------------------------------------------------------

axiosInstance.post = (reqUrl, reqParams) => {
	//Save the cancel token for the current request
	cancelToken = axToken.generateCancellationToken();
	return axiosInstance.request({
		url: reqUrl,
		method: "POST",
		data: reqParams,
		//cancelToken: cancelToken.token
	})
}

const CancelAxiosRequest = () => {
	cancelToken.cancel("Operation cancelled due to new request");
}

export default {
	get: axiosInstance.get,
	post: axiosInstance.post,
	put: axiosInstance.put,
	delete: axiosInstance.delete,
	cancel: CancelAxiosRequest
};