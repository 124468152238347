import client from "../../../services/serviceClient";
import http from "../../../services/httpService";
import { apiUrl } from "../../../environment";

/** ****************************************8Service to fetch the list of business partners ****************************/
const getCustomerMappingLists = (a_guid,  mapping_guid,loginedCustomer,loginedSupplier,country, product ) =>
  client.post(`/supplychain/customer-supplier-mapping/get-lists`, { a_guid ,  mapping_guid,loginedCustomer,loginedSupplier,country, product});

const createMapping = ({
  selectedContact,
  selectedCustomer,
  selectedSupplier,
  selectedRate,
  selectedDateType,
  unlock_period,
  mapping_guid,
  bp,
  interaction,
  product,
  categoryData,
  other_programs = 'no'
}) =>
  client.post(`/entity/entity/create-mapping`, {
    selectedContact,
    selectedCustomer,
    selectedSupplier,
    selectedRate,
    selectedDateType,
    unlock_period,
    mapping_guid,
    bp,
    interaction,
    product,
    categoryData,
    other_programs
  });

/** ****************************************Service to fetch the list of business partners  end****************************/

/************************************* function to get the invoice amount on approval of invoices **********************************************/
const getInvoiceAmount = ({
  invoices,
  customer,
  revised_date_option,
  revised_date = "",
  product
}) =>
  client.post(`/supplychain/fifo-sc-transaction/calculate-approval-amount`, {
    invoices,
    customer,
    revised_date_option,
    revised_date,
    product
  });
/********************* function to get the invoice amount on approval of invoices--end ******************************** */

/* **********************************function to check whether there any invoices require the attention *************************/
const checkTerms = ({ invoices, revised_date_option, revised_date = "",product }) =>
  client.post(`/supplychain/fifo-sc-transaction/check-terms`, {
    invoices,
    revised_date_option,
    revised_date,
    product
  });

/* **********************************function to check whether there any invoices require the attention end *************************/

const createBulkApproval = ({
  invoices,
  userType,
  revised_date_option,
  revised_date,
  approval,
  product,
  status
}) =>
  client.post(`/supplychain/fifo-sc-transaction/bulk-approval-customer`, {
    invoices,
    userType,
    revised_date_option,
    revised_date,
    approval,
    product,
    status
  });

const createBulkApprovalAccepted = ({
  invoices,
  userType,
  revised_date_option,
  revised_date,
  accepted_invoices,
  approval,
  product,
  status
}) =>
  client.post(
    `/supplychain/fifo-sc-transaction/bulk-approval-customer-accept`,
    {
      invoices,
      userType,
      revised_date_option,
      revised_date,
      accepted_invoices,
      approval,
      product,
      status
    }
  );
const getApprovalBalance = ({ invoices, customer, product }) =>
  client.post(`/supplychain/fifo-sc-transaction/approval-balance`, {
    invoices,
    customer,
    product
  });

/** function to get Client Cron Jobs */
const getClientCronJobs = ({ guid }) =>
client.post(`/supplychain/entity-settings/client-cron-jobs`, {
  guid,
});

/** function to get SCFtransaction 4 status */
const getSCFBulkPayment = ({ guid,status_overdue,type,docType,requested,pageLimit }) =>
client.post(`/supplychain/fifo-sc-transaction/get-scf-bulk-transaction`, {
  guid,status_overdue,type,docType,requested,pageLimit
});

/** function to get SCFtransaction Client payment request */
const getSCFClientPayment = ({ guid,status_overdue,type,docType,requested,page,pageSize,fromDate,toDate,country,owner,customer,supplier}) =>
client.post(`/supplychain/fifo-sc-transaction/client-payment-request`, {
  guid,status_overdue,type,docType,requested,page,pageSize,fromDate,toDate,country,owner,customer,supplier
});


//function to get bulk invoice list 4 status with finance cost based on selected date
const getSCFBulkPayDateBase = ({ guid,date_selected,product_type,type,pageLimit=null }) =>
client.post(`/supplychain/fifo-sc-transaction/get-scf-bulk-pay-date-base`, {
  guid,date_selected,product_type,type,pageLimit
});

/** function to get Client Cron Jobs */
const getReminder = ({ guid, cron_id }) =>
client.post(`/supplychain/entity-settings/get-reminder`, {
  guid,
  cron_id,
});

/** function to save Reminder client jobs */
const saveReminders = (data, customer) =>
  client.post(`/supplychain/entity-settings/save-reminder-jobs`, {
    data,
    customer,
  });

/** function to get account details of a user when the guid is provided */
const getAccountDetails = ({ guid,product }) =>
  client.post(`/supplychain/entity-settings/account-details`, {
    guid,product
  });
/** function to get account details of a user when the guid is provided */
const saveAccountDetails = (data, customer) =>
  client.post(`/supplychain/entity-settings/save-account-details`, {
    data,
    customer,
  });
/** function to get notification details of a user when the guid is provided */
const getNotifications = ({ guid }) =>
  client.post(`/supplychain/notification/get-notifications`, {
    guid,
  });
/** function to get notification details of a user when the guid is provided */
const saveNotifications = (data, guid) =>
  client.post(`/supplychain/notification/save-notifications`, {
    data,
    guid,
  });
const updateTransaction = (trans_guid, signature, date) =>
  client.post(`/supplychain/fifo-sc-transaction/update-confirmation`, {
    trans_guid,
    signature,
    date,
  });
// Service to fetch the list of countries
const getCountriesList = () =>
  client.post(`/core/countries/get-countries-array`);
// service to reject invoices from bulk reject
const rejectInvoices = (data, invoices,status,product_type) =>
  client.post(`/supplychain/fifo-sc-transaction/reject-invoice`, {
    data,
    invoices,
    status,
    product_type
  });
  // service to verify invoice-either approval or decline
  const verifyInvoice = (comment, invoice,status,product_id) =>
    client.post(`/supplychain/fifo-sc-transaction/verify-invoice`, {
      comment,
      invoice,
      status,
      product_id
    });
  // service to get funds available  for an entity

  const getFundsAvailable = () =>client.post(`/supplychain/fifo-sc-transaction/funds-available`);
//service to get count of invoices with statis


const getScUserCount = params => client.post(`/supplychain/fifo-sc-transaction/get-sc-user-count`, { ...params });
const GetScInvoiceStatusCount = params =>client.post(`/supplychain/fifo-sc-transaction/get-sc-invoice-status-count`,{...params});
const getScCount = params => client.post(`/supplychain/fifo-sc-transaction/get-sc-counts`, { ...params });
const getOverdueCount  = params => client.post(`/supplychain/fifo-sc-transaction/get-over-due-count`, { ...params });
const checkUserExist = params => client.post(`/entity/entity/check-user-by-abn-or-email`, { ...params });
const createUserWithMapping = params => client.post(`/entity/entity/create-entity-with-mapping`, { ...params });
const mapClient = params => client.post(`/entity/entity/map-client`, { ...params });
const getDefaultItems = params => client.post(`/supplychain/entity-settings/get-default-items`, { ...params });
const saveDefaultSettings = params => client.post(`/supplychain/entity-settings/save-default-settings`, { ...params });
const quickSearch = params => client.post(`/supplychain/fifo-sc-transaction/quick-trans-search?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const commonSearch = params => client.post(`/core/general/search-transactions`, { ...params, selectedTab: params.selectedTab === 'all' ? null : params.selectedTab });
const scApprovedCount = params => client.post(`/supplychain/fifo-sc-transaction/get-approve-for-payment-count?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const createRate = params => client.post(`/supplychain/fifo-sc-rates/create-rate`, { ...params });
const scOverdueCount = params => client.post(`/supplychain/fifo-sc-transaction/get-sc-overdue-count?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const getBpRates = (params) => client.post(`/supplychain/customer-supplier-mapping/get-bp-rates`, {...params});
const getSupplierContacts =(params) => client.post(`/supplychain/customer-supplier-mapping/get-supplier-contacts`, {...params});
const getApprovedPaidGraph = (params) =>client.post(`/supplychain/fifo-sc-transaction/get-approved-paid-graph`, {...params});

const getScFund = params => client.post(`/supplychain/fifo-sc-transaction/get-sc-fund`, { ...params });
const getScSupData   =  params => client.post(`/supplychain/fifo-sc-transaction/get-sc-supplier-update`, { ...params });
const getScApprovedData  =  params => client.post(`/supplychain/fifo-sc-transaction/get-sc-approved-payment-invoice`, { ...params });
const getScAwaitApprovData = params => client.post(`/supplychain/fifo-sc-transaction/get-sc-await-approve-invoice`, { ...params });
const getScApprovepaidCount =  params => client.post(`/supplychain/fifo-sc-transaction/get-sc-approve-paid-invoice`, { ...params });
const getScInvoiceCount = params => client.post(`/supplychain/fifo-sc-transaction/get-sc-invoice-count`, { ...params });
const getClientGraph = params =>client.post(`/supplychain/fifo-sc-transaction/get-client-graph`, { ...params });
const getPrincipalOverdue = params =>client.post(`/supplychain/fifo-sc-transaction/get-principal-overdue`, { ...params });

const multipleUserMapping = params => client.post(`/entity/entity/multiple-user-mapping`, { ...params });

//service to map an alread existing entity with draft secondary account
const mapDraftSecondary = params => client.post(`/entity/entity/map-draft-secondary`, { ...params });


// bulk payment update
const bulkPaymentUpdate = params => client.post(`/supplychain/fifo-sc-transaction/bulk-payment-update`, { ...params });
const dashboardScApprovedCount = params => client.post(`/supplychain/fifo-sc-transaction/fetch-approved-for-payment-count?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const saveEntityOtherSettings = params => client.post(`/supplychain/entity-settings/save-other-settings`, { ...params });
//client bulk payment 
const clientBulkPaymentUpdate = params => client.post(`/supplychain/fifo-sc-transaction/client-bulk-payment-update`, { ...params });
const clientPayRequest = params => client.post(`/supplychain/fifo-sc-transaction/client-pay-request`, { ...params });
const clientPayReqReject = params => client.post(`/supplychain/fifo-sc-transaction/client-pay-req-reject`, { ...params });
const getExternalApis = params => client.post(`/supplychain/fifo-sc-transaction/get-external-apis`, { ...params });
const connectApiExternal = params => client.post(`/externalapis/hubspot/get-connection-status`, { ...params });
const getHubspotContacts = params => client.post(`/externalapis/hubspot/get-hubspot-contacts`, { ...params });
const importHubspotContacts = params => client.post(`/externalapis/hubspot/import-hubspot-contacts`, { ...params });
const connectForwardAi = params => client.post(`/externalapis/forward-ai/connect-forward-ai`, { ...params });
const fetchExtApiCredentials = params => client.post(`/externalapis/hubspot/fetch-ext-api-credentials`, { ...params });
const updateExtApiCredentials = params => client.post(`/externalapis/hubspot/update-ext-api-credentials`, { ...params });

//Service to fetch connection status to forwardAI
const FetchConnectionStatus = params => client.post(`/externalapis/forward-ai/fetch-connection-status`, { ...params });
const CancelConnectionRequest = params => client.post(`/externalapis/forward-ai/cancel-subscription`, { ...params });
//const FetchBusinessContacts = params => client.post(`/api/business-contacts/fetch-business-contacts`, { ...params });


const FetchBusinessContacts = params => http.post(`${apiUrl}/api/business-contacts/fetch-business-contacts`, { ...params }, null);


const checkDuplicateInvNumber = params => client.post(`/supplychain/fifo-sc-transaction/check-duplicate-inv-number`, { ...params });
const getTfPendingPayments = params => client.post(`/supplychain/fifo-sc-transaction/get-tf-pending-payments`, { ...params });
const getTfPaymentRequest = params => client.post(`/supplychain/fifo-sc-transaction/tf-payment-request`, { ...params });
const getTfFund = params => client.post(`/supplychain/fifo-sc-transaction/get-tf-fund`, { ...params });
const generateUndisclosedDetails = params => client.post(`/entity/entity/generate-undisclosed-details`, { ...params });
const fetchTemplates = params =>   client.post(`/mail/mail/fetch-templates`, { ...params });
const viewData = params =>  client.post(`/mail/mail/view-data`, { ...params });
const fetchMailSchedulerData = params =>  client.post(`/supplychain/fifo-sc-transaction/fetch-mail-scheduler-list`, { ...params });
const getEntitySettings = params => client.post(`/entity/entity/get-entity-settings`, { ...params });
const fetchLimitHistory = params => client.post(`/supplychain/entity-settings/fetch-limit-history`, { ...params });
const updateReferenceNo = params => client.post(`/supplychain/fifo-sc-transaction/update-reference-no`, { ...params });

//Api to fetch shared introducers list
const getSharedIntroducersList = params => client.post(`/supplychain/fifo-sc-rate-general-settings/shared-introducers`, { ...params });

// Api to add / update shared introducers
const AddSharedIntroducers = params => client.post(`/supplychain/fifo-sc-rate-general-settings/add-shared-introducers`, { ...params });

// Api to delete shared introducers
const deleteIntroducers = params => client.post(`/supplychain/fifo-sc-rate-general-settings/delete-introducer`, { ...params });

// Api to get shared introducers count
const getSharedIntroducersCount = params => client.post(`supplychain/fifo-sc-rate-general-settings/introducers-count`, { ...params });
const getSalesInvoiceCount = params => client.post(`/supplychain/fifo-sc-transaction/get-sales-invoice-count`, { ...params });

//Api to update mapping
const updateMapping = params => client.post('/entity/entity/update-mapping', { ...params });

//Api to send email from mail scheduler
const sendMail = params => client.post('/core/mail-templates/send-mail', { ...params });

//Api to send bulk email from mail scheduler - payment remainder
const sendBulkMail = params => client.post('/core/mail-templates/send-bulk-mail', { ...params });

const getClosedOutstandingInvoices = params => client.post('/supplychain/fifo-sc-transaction/closed-outstanding-invoices', { ...params });
const getTotalFees = params => client.post('/supplychain/fifo-sc-transaction/client-reconcile-summary', { ...params });
const getClientPaymentAll = params => client.post('/supplychain/fifo-sc-transaction/client-payment-all', { ...params });
const otherAmountAllocation = params => client.post('/supplychain/fifo-sc-transaction/other-amount-allocation', { ...params });
const invoicesForOtherAmount = params => client.post('/supplychain/fifo-sc-transaction/invoices-for-other-amounts', { ...params });
const saveOtherPayment = params => client.post('/supplychain/fifo-sc-transaction/save-other-payment-allocation', { ...params });
const rejectOtherPayment = params => client.post('/supplychain/fifo-sc-transaction/reject-other-amount', { ...params });
const removePayment = params => client.post('/supplychain/fifo-sc-transaction/delete-payment-request', { ...params });

const getDisbursementGraph = (params) =>client.post(`/dftransactions/transactions/get-disbursement-graph`, {...params});
const getLoanGraph = (params) =>client.post(`/loans/loan/get-loan-graph`, {...params});


export default {
  getCustomerMappingLists,
  createMapping,
  getInvoiceAmount,
  checkTerms,
  createBulkApproval,
  createBulkApprovalAccepted,
  getApprovalBalance,
  getAccountDetails,
  saveAccountDetails,
  getNotifications,
  saveNotifications,
  updateTransaction,
  getCountriesList,
  rejectInvoices,
  verifyInvoice,
  getFundsAvailable,
  getScCount,
  getScUserCount,
  GetScInvoiceStatusCount,
  getOverdueCount,
  checkUserExist,
  createUserWithMapping,
  mapClient,
  getDefaultItems,
  saveDefaultSettings,
  quickSearch,
  commonSearch,
  scApprovedCount,
  createRate,
  scOverdueCount,
  getBpRates,
  getSupplierContacts,
  getApprovedPaidGraph,
  getScFund,
  getScSupData,
  getScApprovedData,
  getScApprovepaidCount,
  getScInvoiceCount,
  getClientGraph,
  getPrincipalOverdue,
  multipleUserMapping,
  getClientCronJobs,
  getReminder,
  saveReminders,
  getSCFBulkPayment,
  bulkPaymentUpdate,
  dashboardScApprovedCount,
  saveEntityOtherSettings,
  clientBulkPaymentUpdate,
  clientPayRequest,
  clientPayReqReject,
  getSCFBulkPayDateBase,
  checkDuplicateInvNumber,
  getTfPendingPayments,
  getTfFund,
  getExternalApis,
  connectApiExternal,
  generateUndisclosedDetails,
  getHubspotContacts,
  importHubspotContacts,
  connectForwardAi,
  fetchExtApiCredentials,
  updateExtApiCredentials,
  FetchConnectionStatus,
  CancelConnectionRequest,
  FetchBusinessContacts,
  fetchTemplates,
  fetchMailSchedulerData,
  mapDraftSecondary,
  getEntitySettings,
  fetchLimitHistory,
  updateReferenceNo,
  getSharedIntroducersList,
  AddSharedIntroducers,
  deleteIntroducers,
  getSharedIntroducersCount,
  getSalesInvoiceCount,
  updateMapping,
  sendMail,
  sendBulkMail,
  getSCFClientPayment,
  getTfPaymentRequest,
  getClosedOutstandingInvoices,
  getTotalFees,
  getClientPaymentAll,
  otherAmountAllocation,
  invoicesForOtherAmount,
  saveOtherPayment,
  rejectOtherPayment,
  removePayment,
  getDisbursementGraph,
  getLoanGraph
};
