import React, { useState, useEffect, useRef } from "react";
import Table from "../common/tables/ActionTable";
import { BreadCrumbs } from "../common/layout";
import { Helmet } from "react-helmet";
import authService from "../../../src/services/authService";
import entityService from "../../services/entityService";
import { CountriesList, BPList } from '../slplusTools';
import { Picker, ViewButton } from "../common/inputs";
import { Link } from "react-router";
import Select from 'react-select';

import "../../styles/react-table.css"
import "react-table/react-table.css";

function AllMembers() {
    
    const tableRef = useRef();

    const { constants, category } = authService.getCurrentUser();

    const [country, setCountry] = useState(null);
    const [bp, setBp] = useState(null);
    const [accountType, setAccountType] = useState("all");
    const [businessType, setBusinessType] = useState("all");
    const [showExport, setShowExport] = useState(false);
    const [tableParams, setTableParams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");

    const loadData = async () => {
        setIsLoading(true);
        try {
            const response = await entityService.searchTeamMembers({
                bp,
                accountType,
                businessType
            });

            const formattedOptions = response.data.map(item => ({
                value: item.value,
                label: item.label,
                'data-email': item.email,
                'data-phone_no': item.phone_no,
                'data-first_name': item.first_name,
                'data-last_name': item.last_name
            })); 
            setSearchOptions(formattedOptions); 
        } catch (error) {
            console.error("Failed to load data", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterOption = (option, inputValue) => {
        const email = option.data && option.data['data-email'] ? option.data['data-email'] : '';
        const phoneNo = option.data && option.data['data-phone_no'] ? option.data['data-phone_no'] : '';
        const firstName = option.data && option.data['data-first_name'] ? option.data['data-first_name'] : '';
        const lastName = option.data && option.data['data-last_name'] ? option.data['data-last_name'] : '';
        const label = option.label ? option.label : '';

        // Filter based on email, phone number, or label (case-insensitive)
        return (
            email.toLowerCase().includes(inputValue.toLowerCase()) ||
            phoneNo.toLowerCase().includes(inputValue.toLowerCase()) ||
            firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
            lastName.toLowerCase().includes(inputValue.toLowerCase()) ||
            label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    useEffect(() => {
        loadData();
    }, []); 

    const VerificationStatusBadge = ({status}) => {

        const getBadgeStyle = (status) => {
            switch(status){
                case 'not_verified' :
                    return "danger";
                case 'verified' :  
                    return 'primary';
                case 'pending' :
                    return 'warning'; 
                default :
                    return 'primary';          
            }
        }

        const formatStatusText = (status) => {
            const words = status.split('_');
            return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        };

        return(
            <span className={`badge badge-${getBadgeStyle(status)}`}>{formatStatusText(status)}</span>
        );
    }

    const handleBpChange = async (optionVal) => {
        await setBp(optionVal === null ? "all" : optionVal);
        tableRef.current.reloadData()
    }
    const handleAccountTypeChange = async (optionVal) => {
        await setAccountType(optionVal);
        tableRef.current.reloadData()
    }
    const handleBusinessTypeChange = async (optionVal) => {
        await setBusinessType(optionVal);
        tableRef.current.reloadData()
    }

    const handleSearchKeywordChange = async (optionVal) => {
        await setSearchKeyword(optionVal);
        tableRef.current.reloadData()
    }

    const accountTypes = [
        { label: "All Accounts", value: "all" },
        { label: "Primary Accounts", value: "primary" },
        { label: "Secondary Accounts", value: "secondary" }
    ]

    const businessTypes = [
        { label: "All", value: "all" },
        { label: "Corporate", value: "Corporate" },
        { label: "Network", value: "Network" }
    ]
   
    const columns = [
        {
            Header: "Organization",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.organization}</span>,
        },
        {
            Header: "First Name",
            accessor: "first_name",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.first_name}</span>,
        },
        {
            Header: "Last Name",
            accessor: "last_name",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.last_name}</span>,
        },
        {
            Header: "Email",
            accessor: "email",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.email}</span>,
        },
        {
            Header: "Phone No",
            accessor: "phone_no",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.phone_no}</span>,
        },
        {
            Header: "User Access",
            accessor: "user_access",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: (row) => (
                <span
                  title={row.original.error}
                  className={`badge badge-${
                    row.original.uid ? "primary" : "danger"
                  }`}
                >
                  {row.original.uid ? "Yes" : "No"}
                </span>
              ),
        },
        {
            Header: "Phone Verification",
            accessor: "phone_verification",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: (row) => <VerificationStatusBadge status={row.original.phone_verification_status}/>,
        },
        {
            Header: "Action",
            accessor: "action",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: row => {
                return (
                    <>
                        <Link
                            className="action-icons"
                            to={{ pathname: `/entities/members/profile`, search: "?contact_id=" + row.original.contact_id }}
                        >
                            <ViewButton></ViewButton>
                        </Link> &nbsp;
                    </>
                )
            }
        },
    ];

    const exportExcel = async () => {
        setIsLoading(true)
        
        const res = await entityService.listAllMembers({
            ...tableParams,
            pageSize: null,
            download: true
        });
        
        const newWindow = window.open(res.data);
        newWindow.opener = null;
    
        setIsLoading(false);
      };

    const ActionButtons = () => (
        <>
         {showExport && <Link className="btn btn-outline-secondary pull-right" onClick={exportExcel} label="Export" >
          {isLoading ? "Loading..." : "Export"}
         </Link> }
        </>
    );

    const FilterOptions = () => {
        const customStyles = {
            menu: provided => ({ ...provided, zIndex: 999 })
        };

        return(
            <>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <Select
                            options={searchOptions} // Pass the search options
                            isSearchable
                            getOptionLabel={option => `${option.label}`}
                            getOptionValue={option => option.value}
                            filterOption={filterOption}
                            onChange={({ value }) => handleSearchKeywordChange(value)}
                            styles={customStyles}
                            value={searchOptions.find(item => item.value === searchKeyword)}
                        />
                    </div>
                    {
                        category == constants.CATEGORY.INTL_OFFICE && (
                            <div className="col-lg-6 mb-3">
                                <CountriesList
                                    type="vertical"
                                    selectedCountry={country}
                                    onSelectItem={({ id }) => setCountry(id)}
                                />
                            </div>
                        )
                    }

                    {
                        (category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) && (
                            <div className="col-lg-6 mb-3">
                                <BPList
                                    selectedBp={bp}
                                    type="vertical"
                                    country={country}
                                    onSelectItem={({ value }) => handleBpChange(value)}
                                />
                            </div>
                        )

                    }

                    <div className="col-lg-6 mb-2">
                        <div className="form-group">
                            <Picker
                                items={accountTypes}
                                selectedItem={accountTypes.find(item => item.value === accountType)}
                                onSelectItem={({ value }) => handleAccountTypeChange(value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <Picker
                                items={businessTypes}
                                selectedItem={businessTypes.find(item => item.value === businessType)}
                                onSelectItem={({ value }) => handleBusinessTypeChange(value)}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>All Team Members | {global.config.title}</title>
            </Helmet>
            <BreadCrumbs Header="All Team Members" activeModule="All Team Members" Route1="/dashboard"
            Label1="Dashboard" /> <br />
            <div className="card overflow-visible">
                <div className="card-header">
                    <Table
                        ref={tableRef}
                        service={entityService.listAllMembers}
                        cols={columns}
                        tableLeftPanel={<FilterOptions />}
                        tableRightPanel={<ActionButtons />}
                        onDataLoad={(data, tableParams) => { // Callback function to trigger after populating data on Action table
                            setShowExport(data.rows.length > 0 ? true : false);
                            setTableParams(tableParams);
                        }}
                        reqParams={{
                            searchKeyword,
                            bp,
                            accountType,
                            businessType
                        }}
                    />
                </div>
            </div>    
        </>
    );
}

export default AllMembers;
