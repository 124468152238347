import React, { Component } from "react";
import { Link } from "react-router";
import Modal from 'react-bootstrap/Modal';

import authService from "../../../../services/authService";
import EntityContext from "../../../../context/entityContext";
import NotificationContext from "../../../../context/notificationContext";

import { getInitials } from "../../../../utils/NameInitials";
import "../../css/client.css";
import LoadingSpinner from '../../../common/loaders/loadingSpinner';
import CreateInvoice from "../../pages/ifTransactions/_createInvoice";
import SwitchOrganisation from "./_switchOrganisation";
import { browserHistory } from "react-router";
import CreateInvvoice from "../../../supply-chain/components/transactions/supplierUpload/index";
import CreateInvvoiceCustomer from "../../../supply-chain/components/transactions/customerUpload/index";
import SearchTransactions from "../../pages/ifTransactions/searchTransactions";
import config from "../../../../services/configService";
import ClientMenu from "./_mainMenu";
import fpClientLogo from "../../images/fp-cl-logo.jpg";
import fpClientIcon from "../../images/fp-cl-icon.svg";
import {
    GeneralButton,
} from "../../../common/inputs";
import { ShowCustomAlert } from "../../../../utils/alertUtils";


class Header extends Component {

    static contextType = EntityContext;

    constructor() {
        super();
        this.state = {
            invTransModal: false,
            switchOrganisationModal: false,
            selectedRole: null,
            entityId: null,
            selectedTab: 0,
            searchTransModal: false,
            topAlert: {},
            isNotify: false,
            displayCustomBanner: false,
            notificationInfo: null
        }
        this.state = { windowWidth: window.innerWidth };
    }

    handleLogout = () => {
        authService.logout();
        browserHistory.push("/client/login");
    }
    handleChangePassword = () => {
        browserHistory.push("/client/changepassword")
    }
    handleProfile = () => {
        browserHistory.push("/client/profile")
    }
    onCloseModal = () => {
        this.setState({ open: false });
    };
    onOpenModal = () => {
        this.setState({ open: true });
    };
    onOpenModalCustomer = () => {

        this.setState({ openCustomer: true });
    };
    onCloseModalCustomer = () => {
        this.setState({ openCustomer: false });
    };
    //----------------Listening to window resize-----------------------
    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    //--------------------------------------------------------
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        const contactData = authService.getCurrentUser();
        const clientCountry = contactData.country_id;
        const status = 'active';
        this.fetchTopAlert(clientCountry, status);
    }
    //--------------------------------------------------------

    fetchTopAlert = async (clientCountry, status) => {
        try {
            const { data } = await config.getTopHeaderAlertCheck(clientCountry, status);
            this.setState({ topAlert: data.rows });

            if (data.rows?.[0]?.show_popup_in_portal === "1" && 
                (authService.getLoginMinutes() === 0)) {
                    this.setState({ 
                        displayCustomBanner: true,
                        notificationInfo: data.rows[0]
                    })
            }
        } catch (error) {
            console.error("Error fetching top alert:", error);
        }
    };

    // Endfbc
    //----------------Trimming organization based on screen-----------------
    handleOrgName = org => {
        if (this.state.windowWidth <= 1440) {
            return org.substr(0, 15);
        }
        else {
            return org;
        }
    };
    //---------------------------------------------------------------

    render() {

        const contactData = authService.getCurrentUser();
        let selectedOrganisation = this.context.entityDetails !== null ? this.context.entityDetails.trading_name ? this.context.entityDetails.trading_name : this.context.entityDetails.organization : null;
        const { entityDetails, handleRoleChange, handleProductChange } = this.context;

        const { activePath } = this.props;
        return (
            <>
                {this.state.displayCustomBanner && (
                    <ShowCustomAlert 
                        alertData={this.state.notificationInfo} 
                        onClose={() => {
                            this.setState({ 
                                displayCustomBanner: false, 
                                notificationInfo: null
                            })
                        }}
                    />
                )}
                {/*------------------Start Transaction Modal-------------------*/}
                <Modal show={this.state.invTransModal} onHide={() => this.setState({ invTransModal: false })} size="lg" >
                    <CreateInvoice onClose={() => this.setState({ invTransModal: false })} />
                </Modal>
                {/*-------------------------------------------------------------*/}

                {/*------------------Switch Organisation Modal-------------------*/}
                <Modal show={this.state.switchOrganisationModal} onHide={() => this.setState({ switchOrganisationModal: false })} size="lg">
                    <SwitchOrganisation onClose={() => this.setState({ switchOrganisationModal: false })} />
                </Modal>

                {/*------------------Search Transaction Modal-------------------*/}
                <Modal show={this.state.searchTransModal} onHide={() => this.setState({ searchTransModal: false })} size="lg">
                    <SearchTransactions onClose={() => this.setState({ searchTransModal: false })} a_guid={entityDetails ? entityDetails.a_guid : ""} />
                </Modal>
                {/*-------------------------------------------------------------*/}

                {/*-------------------------Top alert box-----------------------------*/}
                {this.state.topAlert != undefined && this.state.topAlert.map((obj, key) =>
                    <nav key={key} className="navbar bg-secondary">
                        <div className="container-fluid d-flex justify-content-between align-items-center w-100">
                            <p className="my-lg-0"><strong className="text-white">{obj.heading}</strong><small className="text-white opacity-50"> -
                                {obj.description}</small></p>
                            <div className="d-flex">
                                {obj.button_name.trim().length > 0 && <button type="submit" className="btn btn-primary btn-sm " onClick={() => { window.open(obj.action_url) }}>{obj.button_name}</button>}
                                <button className="btn btn-link ml-2" to="#" onClick={() => { this.setState({ topAlert: undefined }); }}>
                                    <i className="fa fa-times-circle text-white opacity-50"></i>
                                </button>
                            </div>
                        </div>
                    </nav>
                )}
                {/*-------------------------Top alert box ends-----------------------------*/}

                {/*-------------------------Top alert box-----------------------------*/}

                {contactData.bp_view &&
                    <nav className="navbar bg-danger">
                        <div className="container-fluid d-flex justify-content-between align-items-center w-100">
                            <p className="my-lg-0">

                                <strong className="text-white  "> <i className="fa fa-warning mr-2"></i>{'You are viewing as a business partner'}</strong>
                                <small className="text-white opacity-50"> </small>
                            </p>
                            <div className="d-flex">
                                <button className="btn btn-link ml-2" to="#" onClick={() => {
                                    authService.logout();
                                    window.open('', 'clientWindow').close();
                                }}>
                                    <i className="fa fa-times-circle text-white opacity-50"></i>
                                </button>
                            </div>
                        </div>
                    </nav>
                }

                {/*-------------------------Top alert box ends-----------------------------*/}

                {/*-------------------------Top Navigation bar starts-----------------------------*/}
                <nav id="cl-top-nav" className="top-navigation  navbar navbar-light navbar-expand-xl bg-white position-sticky fixed-top py-3 py-xl-0 border-bottom" role="navigation">
                    <div className="container-fluid">
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-label="Toggle navigation"><i className="fa fa-reorder" /></button>
                        <Link className="navbar-brand d-none d-sm-block mr-0" onClick={() => { handleRoleChange(null); handleProductChange(null); }} to="/client/home">
                            {/*this.context.loader && <LoadingSpinner normal={true} />*/}
                            {!this.context.loader && <img src={(this.context.entityDetails !== null && this.context.entityDetails.logo) ? this.context.entityDetails.logo : fpClientLogo} className="w-75" />}

                        </Link >
                        <Link className="navbar-brand d-sm-none mr-auto" to="/client/home"> <img src={fpClientIcon} /></Link >

                        <div className="btn-group order-xl-2 mr-xl-3 mr-auto">
                            <button data-toggle="dropdown" className="btn btn-outline-primary dropdown-toggle   btn-sm pr-sm-4 pr-3 pl-1 pl-lg-2 py-1 " aria-expanded="false">
                                <i title="Access Your Company Settings/Features" className="fa fa-th-large mr-1 " />
                                <span className="d-none d-lg-inline-block"> {selectedOrganisation ? this.handleOrgName(selectedOrganisation) : <LoadingSpinner normal={true} />}</span>
                                <span className="d-inline-block d-lg-none">{getInitials(selectedOrganisation, null)}
                                </span>
                            </button >
                            <ul className="dropdown-menu dropdown-menu-right shadow-sm border" x-placement="bottom-start" style={{ position: 'absolute', top: '33px', left: '0px', willChange: 'top, left' }}>
                                <li>
                                    <Link style={{ cursor: 'pointer' }} /*onClick={() => this.setState({ switchOrganisationModal: true })} to="#"*/ className="dropdown-item">
                                        <div onClick={() => this.setState({ switchOrganisationModal: true })} className="d-flex align-items-center" /*data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"*/>
                                            <div className="avatar avatar-sm text-danger mr-2  rounded-full">
                                                <i className="fa fa-refresh" />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="font-medium text-xs"> Switch Organisation </span>
                                            </div>
                                        </div>
                                    </Link >
                                </li>
                                <li className="dropdown-divider" />
                                <li style={{ cursor: 'pointer' }}>
                                    <div className="d-flex align-items-center px-3 py-2">
                                        <div className="media-icon  bg-primary text-white">{getInitials(selectedOrganisation, null)}</div>
                                        <h6 className="text-sm mb-0 ml-2" /*onClick={() => this.setState({ switchOrganisationModal: true })}*/>  {selectedOrganisation}</h6>
                                    </div>
                                </li>
                                <li className="dropdown-divider" />

                                <li> <Link className="dropdown-item" to="/client/settings">Settings</Link ></li>
                                <li>  <Link className="dropdown-item" to="/client/team-members">
                                    Team Members
                                </Link > </li>
                            </ul>
                        </div>
                        {/*--------------Right hand side menu icons-----------------------------*/}
                        <ul className="order-xl-3 nav  navbar-right  flex-nowrap align-items-center ml-auto">
                            <li className="dropdown nav-item" title="Search">
                                <Link onClick={() => this.setState({ searchTransModal: true })} style={{ cursor: 'pointer' }} /*to="#" data-toggle="dropdown"*/ className="nav-link"><i className="fa fa-search" />  </Link >
                            </li>
                            {/*   <li className="nav-item" title="Mail"> <Link to="#" className="nav-link"><i className="fa fa-envelope" />  </Link ></li>*/}

                            <NotificationContext.Consumer>
                                {({ notifications }) => (
                                    <li className="nav-item" title={`Notifications`}>
                                        <Link aria-expanded="false" role="button" to="#" data-toggle="dropdown" className="nav-link mr-2">
                                            <i className="fa fa-bell" />
                                            {notifications && notifications.filter(item => item.read === "0").length > 0 &&
                                                <span className="label label-primary ml-1 rounded-2xl">{notifications.filter(item => item.read === "0").length}</span>
                                            }
                                        </Link>
                                        <ul role="menu" className="dropdown-menu dropdown-menu-right dropdown-menu-arrow border">
                                            {notifications && notifications.filter(item => item.read === "0").slice(0, 5).map((item, index) => (
                                                <Link to={{ pathname: "/client/notification/view", state: item }} key={index} className="dropdown-item">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex flex-column">
                                                            <span className="font-medium text-xs">{item.title}</span>
                                                            <span className="text-xs opacity-75">
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: item.notification }}
                                                                ></div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                            <li className="dropdown-divider" />

                                            {notifications && notifications.filter(item => item.read === "0").length > 0 ?
                                                <>
                                                    <Link className="dropdown-item font-weight-strong text-secondary" to={`/client/client-side-notifications`}>
                                                        <div className="row justify-content-center" >
                                                            <div className="d-flex flex-column">

                                                                <span className="font-medium text-xs">
                                                                    All Notifications
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </>
                                                : <div className="row justify-content-center" >
                                                    <div className="d-flex flex-column">
                                                        <span className="font-medium text-xs">
                                                            No New Notifications
                                                        </span>
                                                    </div>
                                                </div>}

                                        </ul>
                                    </li>
                                )}
                            </NotificationContext.Consumer>

                            {/*    <li className="nav-item" title="Help"> <Link to="#" className="nav-link mr-2"><i className="fa fa-info" /> </Link > </li> */}
                            <li className="dropdown nav-item ml-2" title={`${contactData.contact_name} ${contactData.contact_lname}`}> <Link aria-expanded="false" role="button" to="#" data-toggle="dropdown" className="nav-link avatar bg-primary  d-flex text-white"> {getInitials(contactData.contact_name, contactData.contact_lname)} </Link >
                                <ul role="menu" className="dropdown-menu dropdown-menu-right dropdown-menu-arrow border">
                                    <li > <Link to="#" className="dropdown-item">
                                        <div to="#" className="d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div title={contactData.contact_name} className="avatar avatar-md text-success mr-2 bg-success-transparent rounded-full">
                                                {getInitials(contactData.contact_name, contactData.contact_lname)}
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="font-semibold text">{contactData.contact_name}&nbsp;{contactData.contact_lname}</span><span className="text-xs opacity-75"></span>
                                            </div>
                                        </div>
                                    </Link ></li>

                                    <li className="dropdown-divider" />

                                    <li>
                                        <Link to="/client/profile" className="dropdown-item">
                                            <div className="d-flex align-items-center" >
                                                <div className="avatar avatar-sm mr-2  rounded-full">
                                                    <i className="fa fa-user" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <span className="font-medium text-xs">My Account</span><span className="text-xs opacity-75">Profile Info..</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/client/changepassword" className="dropdown-item">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar avatar-sm mr-2  rounded-full">
                                                    <i className="fa fa-key" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <span className="font-medium text-xs">Change Password</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="dropdown-divider" />
                                    <li> <Link style={{ cursor: "pointer" }} onClick={() => this.handleLogout()} className="dropdown-item">
                                        <div to="#" className="d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="avatar avatar-sm text-danger mr-2  rounded-full">
                                                <i className="fa fa-sign-out" />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="font-medium text-xs">Sign Out</span>
                                            </div>
                                        </div>
                                    </Link >


                                    </li>

                                </ul>
                            </li>
                        </ul>
                        {/*--------------Right hand side menu icons ends-----------------------------*/}

                        {/*----------------Main Menu start---------------------------*/}
                        <ClientMenu activePath={activePath}></ClientMenu>
                    </div>
                </nav>
                {/*-------------------------Top Navigation bar ends-----------------------------*/}
                <Modal show={this.state.open} onHide={this.onCloseModal} size="lg" >

                    <CreateInvvoice a_guid={entityDetails ? entityDetails.a_guid : ""} page="common" onCloseModal={() => { this.onCloseModal(); }} ></CreateInvvoice>

                </Modal>

                <Modal show={this.state.openCustomer} onHide={this.onCloseModalCustomer} size="lg"  >

                    <CreateInvvoiceCustomer a_guid={entityDetails ? entityDetails.a_guid : ""} page="common" onCloseModal={() => { this.onCloseModalCustomer(); }} ></CreateInvvoiceCustomer>

                </Modal>

            </>
        );
    }
}

export default Header;