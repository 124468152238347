import React, { useState } from "react"
import Modal from "react-bootstrap/Modal";
import { browserHistory } from "react-router";

/**
 * Utility function to show a custom alert popup
 * @param {Object} alertData - Data containing heading, description, button_name, and action_url
 */
export const ShowCustomAlert = ({ alertData, onClose, userType = 'client' }) => {

    let heading, description, button_name, action_url;

    if (userType === 'client') {
        ({ heading, description, button_name, action_url } = alertData);
    } else {
        ({ central_heading: heading, central_description: description, central_button_name: button_name, central_action_url: action_url } = alertData);
    }


    const isExternalUrl = action_url ? (action_url.startsWith('http://') || action_url.startsWith('https://')) : false;

    return (
        <>
            <Modal show={true}>
                <Modal.Body className="bg-secondary text-white p-5">
                    <div className="text-center">
                        <h6 className="font-weight-bold">{heading}</h6>
                        <p>{description}</p>
                        <div className="mt-4">
                            <button
                                className="btn btn-white mr-2"
                                onClick={onClose}
                            >
                                Close
                            </button>

                            {button_name?.trim() && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (!isExternalUrl)
                                            browserHistory.push(action_url)
                                        else
                                            window.open(action_url)
                                        onClose()
                                    }}
                                >
                                    {button_name}
                                </button>
                            )}
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


        </>
    )
};
