import http from "./httpService";
import client from "./serviceClient";
import { apiUrl, queryString } from "../../src/environment";

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  };
}

export async function createBusinessPartner(
  a_guid,
  ab_number,
  iban_number,
  organization,
  first_name,
  middle_name,
  last_name,
  email,
  status,
  phone_no,
  category,
  street,
  city,
  state,
  country,
  selectedCategories,
  address1,
  address2,
  address3,
  post_code,
  alternate_phone,
  website,
  lead_source,
  referred_by,
  bank_name,
  bank_account_name,
  bsb_number,
  account_number,
  trading_name,
  acn,
  incorporation,
  company_status,
  directors,
  shareholders,
  industry,
  home_address1,
  home_address2,
  home_city,
  home_post_code,
  alternate_email,
  manager_first_name,
  manager_last_name,
  source,
  territory,
  commencement_date,
  training_date,
  first_expiry,
  second_expiry,
  final_expiry,
  purchase_cost,
  tech_fee,
  invoice_finance,
  loans,
  supply_chain,
  renewal_fee,
  trans_fee,
  special_conditions,
  attandance_fee,
  penality_rate,
  advertise_fee,
  bp_entity,
  productSettings,
  partner_type,
  introducer_contact_category
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl + "/entity/entity/create-entity?XDEBUG_SESSION_START=PHPSTORM",
    {
      a_guid,
      ab_number,
      iban_number,
      organization,
      first_name,
      middle_name,
      last_name,
      email,
      status,
      phone_no,
      category,
      street,
      city,
      state,
      country,
      selectedCategories,
      address1,
      address2,
      address3,
      post_code,
      alternate_phone,
      website,
      lead_source,
      referred_by,
      bank_name,
      bank_account_name,
      bsb_number,
      account_number,
      trading_name,
      acn,
      incorporation,
      company_status,
      directors,
      shareholders,
      industry,
      home_address1,
      home_address2,
      home_city,
      home_post_code,
      alternate_email,
      manager_first_name,
      manager_last_name,
      source,
      territory,
      commencement_date,
      training_date,
      first_expiry,
      second_expiry,
      final_expiry,
      purchase_cost,
      tech_fee,
      invoice_finance,
      loans,
      supply_chain,
      renewal_fee,
      trans_fee,
      special_conditions,
      attandance_fee,
      penality_rate,
      advertise_fee,
      bp_entity,
      productSettings,
      partner_type,
      introducer_contact_category
    },
    accessToken
  );
  return data;
}

export async function createEntity(
  a_guid,
  ab_number,
  organization,
  first_name,
  middle_name,
  last_name,
  email,
  status,
  phone_no,
  category,
  street,
  city,
  state,
  country,
  selectedCategories,
  address1,
  address2,
  address3,
  post_code,
  alternate_phone,
  alternate_email,
  website,
  lead_source,
  referred_by,
  bank_account_name,
  bsb_number,
  account_number,
  trading_name,
  acn,
  incorporation,
  company_status,
  directors,
  industry,
  shareholdersData,
  addressList,
  organisation_number,
  organisation_status,
  caution,
  owner,
  cautionCheck
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl + "/entity/entity/create-entity?XDEBUG_SESSION_START=PHPSTORM",
    {
      a_guid,
      ab_number,
      organization,
      first_name,
      middle_name,
      last_name,
      email,
      status,
      phone_no,
      category,
      street,
      city,
      state,
      country,
      selectedCategories,
      address1,
      address2,
      address3,
      post_code,
      alternate_phone,
      alternate_email,
      website,
      lead_source,
      referred_by,
      bank_account_name,
      bsb_number,
      account_number,
      trading_name,
      acn,
      incorporation,
      company_status,
      directors,
      industry,
      shareholdersData,
      addressList,
      organisation_number,
      organisation_status,
      caution,
      owner,
      cautionCheck
    },
    accessToken
  );
  return data;
}

export async function createGuarantor(
  a_guid,
  organization,
  first_name,
  last_name,
  email,
  phone_no,
  city,
  country,
  selectedCategories
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl + "/entity/entity/create-entity?XDEBUG_SESSION_START=PHPSTORM",
    {
      a_guid,
      organization,
      first_name,
      last_name,
      email,
      phone_no,
      city,
      country,
      selectedCategories
    },
    accessToken
  );
  return data;
}

/*************************** */

export async function fetchNoteList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/grid-entity-notes?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}
/************************************** */
export async function getEntitiesList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/list-entities?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

/*** Business Partner Invitation  */

export async function getEntitiesInvitataionList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/entity-contact-invitation?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

/******************* */
export async function fetchKeywordSuggestions(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/key-word-auto-suggestions?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}
/******************************************* */

export async function fetchDebtorSuggestions(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/fetch-debtors-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

/******************* */
export async function fetchNoteWordSuggestions(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/key-word-auto-suggestions-note?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

/******************* */
export async function fetchKeywordSuggestionsBp(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/key-word-auto-suggestions-bp?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getBusinessPartnersList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/list-entities?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    type: 2
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityContactsList(entity, search = null) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/list-entity-contacts?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity,
    search
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityCategories(a_guid = "") {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity-category-mapping/get-entity-category-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    a_guid,


  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityParentContactsList(entity) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/list-entity-parent-contacts?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/********************  Fetching Receivable transaction List (Client) */

export async function getRecivables(entity_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/list-receivable-transaction?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/********************  Fetching Receivable transaction List (Client) */

export async function getIntroductions(entity_guid) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/list-introductions?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

/*********  Fetching content to display inside client invitation model ********* */
export async function getClientInvitationMessage(
  selectedEntityContact,
  clientGuid,
  type = 'client'
) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/fetch-client-invitation-message?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    selectedEntityContact,
    clientGuid,
    type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/********************************************************************* */

/********************  Fetching Receivable transaction List (Client) */

export async function getPayables(entity_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/lis-payble-transaction?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}
/********************************************************************* */

export async function getEntityGuarantorsList(additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/list-entity-guarantors?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityDebtorsList(client,product,showAllLabel=false) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/list-entity-debtors?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    client,
    product,
    showAllLabel
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityDebtorsKeywordSuggestions(additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/entity-debtors-key-word-suggestions?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    additionalParams
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityDetails(a_guid, busineess_partner = "", create = "") {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/get-entity-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    a_guid,
    busineess_partner,
    create
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getEntityDetailsProfile(a_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/business-partner-profile?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    a_guid: a_guid
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getEntityDefaults(entityId) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/get-defaults?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entityId
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getTabsList(type, a_guid = "") {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/get-tabs-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    type,
    a_guid
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getCountriesTabList(categoryId, status = null) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/get-countries-tab-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    categoryId: categoryId,
    status: status
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getGuarantorsList(entityId) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/fetch-guarantors-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { entityId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getDebtorsList(entityId) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/fetch-entity-debtors-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { entityId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteEntityGuarantor(guid) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/delete-entity-guarantor?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { guid };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteEntityDebtor(guid) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/delete-entity-debtor?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { guid };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteEntityContact(contactId) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/delete-entity-contact?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { contactId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
export async function deactivateEntityContact(contactId) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/deactivate-entity-contact?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { contactId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}


export async function mapGuarantor(guarantor_guid, entity_guid) {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/map-guarantor?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    guarantor_guid,
    entity_guid
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function mapDebtor(debtor_guid,fee_guid, entity_guid,product,owner) {
  updateHeader();
  let url = apiUrl + "/entity/entity/map-debtor?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    debtor_guid,
    fee_guid,
    entity_guid,
    product,
    owner
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function createDefaults(
  entity_id,
  fee_id,
  late_payment_fee,
  advance_rate,
  insured_fee,
  introducer_fee,
  introducer_fee_type,

  category,
  mail_checked,
  payment_remainder,
  payment_receipt,
  overdue_notice,
  overdue_notice2,
  overdue_notice_days_after_due,
  overdue_notice2_days_after_due,
  payment_reminder_days,
  can_set_advance_amount,
  can_request_payment_date,
  debtor_disclosed
) {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/create-defaults?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_id,
    fee_id,
    late_payment_fee,
    advance_rate,
    insured_fee,
    introducer_fee,
    introducer_fee_type,
    category,
    mail_checked,
    payment_remainder,
    payment_receipt,
    overdue_notice,
    overdue_notice2,
    overdue_notice_days_after_due,
    overdue_notice2_days_after_due,
    payment_reminder_days,
    can_set_advance_amount,
    can_request_payment_date,
    debtor_disclosed
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function handleContactCheckboxChange(
  contact_guid,
  ischecked,
  field,
  entity
) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/entity-contact-checkbox-change?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    contact_guid,
    ischecked,
    field,
    entity
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

// export async function deleteEntity(a_guid) {
//   updateHeader();

//   let url =
//     apiUrl + "/entity/entity/delete-entity?XDEBUG_SESSION_START=PHPSTORM";
//   let postObject = {
//     a_guid
//   };

//   try {
//     const { data } = await http.post(url, postObject, accessToken);
//     return data;
//   } catch (data) {
//     return console.log(data);
//   }
// }

export async function getEntityNotesList(prospect_guid ,checkPriority) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/list-entity-notes?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    prospect_guid,
    checkPriority,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function createUserInvitation(contact_id, selected_category) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/bp-user-invitation?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    contact_id,
    selected_category
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    console.log(response.data);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function deactivateInvitation(contact_id) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/bp-user-deactivate?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    contact_id
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    console.log(response.data);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function createEntityNote(
  note_guid,
  title,
  note,
  reference,
  entity,
  priority_note,
) {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/create-entity-notes?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    note_guid,
    title,
    note,
    reference,
    entity,
    priority_note,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function assignParentContact(
  parentEntityContact,
  selectedEntityContact,
  type
) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/assign-parent-entity-contact?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    parentEntityContact,
    selectedEntityContact,
    type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function copyEntity(a_guid, category) {
  updateHeader();

  let url = apiUrl + "/entity/entity/copy-entity?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    a_guid,
    category
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

// ------ Service to populate entities list in a dropdown menu / multi select tree view based on selected category
export async function GetEntityUsersByCategory(category_id, list) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/fetch-entity-users-list?XDEBUG_SESSION_START=PHPSTORM";

  try {
    const { data } = await http.post(url, { category_id, list }, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

// ------ Service to populate investment partners list in a dropdown menu ---------- //
export async function fetchInvestmentPartners(client_guid) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/fetch-investment-partners-list?XDEBUG_SESSION_START=PHPSTORM";

  try {
    const { data } = await http.post(url, { client_guid }, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function sendClientInvitationMail(mail_to, mail_body, selectedEntityContact, type = 'client', resend = false) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/send-client-invitation-mail?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    mail_to,
    mail_body,
    selectedEntityContact,
    type,
    resend
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return response.data;
  }
}

export async function getTemplatePreview(type) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/display-debtor-notification-template?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return response.data;
  }
}

export async function getUsersToBeNotified(entity_guid, type) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/get-users-to-be-notified?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid,
    type
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    console.log("eroro")
    return console.log(response);
  }
}

export async function sendPaymentNotifications(users, message) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/send-payment-notifications?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    users: users,
    message: message
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchCompaniesListByEntityName(entityName) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/fetch-companies-list-by-entity-name?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { entityName };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function fetchCompanyDetailsByNzbn(selectedNZBN) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/fetch-company-details-by-nzbn?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { selectedNZBN };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function updateProfileInfo(entityId, userData) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/update-basic-address-info?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { entityId, userData };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function bulkUpdate(params) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/entity-bulk-update?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { ...params };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteBulkEntities(entities) {
  updateHeader();
  let url =
    apiUrl +
    "/entity/entity/delete-bulk-entity?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { entities };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getEntityDetailsByAbn(abn) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/get-entity-by-abn?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    abn_number: abn
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function saveClientOrganizatioinDetails(data, fileslist) {
  updateHeader();

  let url =
    apiUrl + "/entity/entity/save-client-organizatioin-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { data, fileslist };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}
export async function fetchClientOrgDetails(entity_guid) {
  updateHeader();
  let url =
    apiUrl + "/entity/entity/fetch-client-org-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_guid
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}


export async function getCrManagersList(entity) {
  updateHeader();

  let url =
    apiUrl +
    "/entity/entity/list-cr-managers?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

const getCountryOfficesList = params => client.post(`/entity/entity/get-country-offices`, { ...params });

const updateReferralPartner = params => client.post(`/entity/entity/update-referral-partner`, { ...params });
const saveEntityCoreFields = params => client.post(`/entity/entity/create-entity`, { ...params });
const saveEntityCoreFieldsPublic = params => http.post(`${apiUrl}/entity/entity/create-entity?XDEBUG_SESSION_START=PHPSTORM`, { ...params });

const listEntities = (params, status) => client.post(`/entity/entity/list-entities`, { ...params, status });
const searchEntities = params => client.post(`/entity/entity/search-entities`, { ...params });
const searchPartners = params => client.post(`/entity/entity/search-partners`, { ...params });
const assignRoles = params => client.post(`/entity/entity/save-entity-categories`, { ...params });
const searchByAbn = params => client.post(`/entity/entity/get-entity-by-abn`, { ...params });
const saveEntityCategoryBp = (params, rmvParams) => client.post(`/entity/entity/save-entity-category-bp`, { params, rmvParams });
const createEntityContact = params => client.post(`/entity/entity/create-entity-contact`, { ...params });
const validateExcelSheet = params => client.post(`/entity/entity/validate-excel-sheet`, { ...params });
const bulkUpload = params => client.post(`/entity/entity/bulk-upload`, { ...params });
const listPartners = (params, status) => client.post(`/entity/entity/list-partners`, { ...params, status });
const entityProducts = params => client.post(`/entity/entity/get-entity-products`, { ...params });
const entityLoginHistory = params => client.post(`/entity/entity/get-entity-contact-login-history`, { ...params });
const getOrganisationsListByContactEmail = params => client.post(`/entity/entity/get-organisations-by-entity-contact`, { ...params });
const getSecondaryProfile = params => client.post(`/entity/entity/get-secondary-profile-info`, { ...params });

const getInviteeInfoByToken = params => http.post(`${apiUrl}/entity/entity/get-invitee-info-by-token`, { ...params });
const verifyContactEmailAgainstToken = params => http.post(`${apiUrl}/entity/entity/verify-contact-email-against-token`, { ...params });
const bulkChangeOwnership = params => client.post(`/entity/entity/bulk-change-ownership`, { ...params });
const listIntroducerContacts = (params, status) => client.post(`/entity/entity/list-introducer-contacts`, { ...params, status });
const bulkBpIntrdcrContactMapping = params => client.post(`/entity/entity/bulk-bp-intrdcr-contact-mapping`, { ...params });
const mappedIntroducers = params => client.post(`/entity/entity/list-mapped-introducers`, { ...params });
const saveDefaultSettings = params => client.post(`/entity/entity/save-default-settings`, { ...params });
const fetchDefaultSettings = params => client.post(`/entity/entity/fetch-default-settings`, { ...params });
const getBpIntroducerCount = params => client.post(`/entity/entity/get-bp-introducer-count`, { ...params });
const fetchSecondaryMappedUsers = params => client.post(`/entity/entity/fetch-secondary-mapped-users`, { ...params });
// To activate Invoice Finance
const activateInvFinance = params => client.post(`/entity/entity/activate-inv-finance`, { ...params });
const quickSearch = params => client.post(`/entity/entity/quick-entity-search`, { ...params });
const getEntityCount = params => client.post(`/entity/entity/get-entity-count`, { ...params });
const getIfAccountsCount = params => client.post(`/invtransactions/widgets/accounts-overview`, { ...params });
const getIfDisbursementsCount = params => client.post(`/invtransactions/widgets/disbursements-overview`, { ...params });
const listEntityContacts = params => client.post(`/entity/entity/get-entity-contacts`, { ...params });
const sendAlert = params => client.post(`/entity/entity/send-alert`, { ...params });
const fetchCountryCode = params => client.post(`/entity/entity/get-country-code`, { ...params });
const bulkSupplierValidation = params => client.post(`/entity/entity/bulk-supplier-validation`, { ...params });
const bulkSupplierUpload = params => client.post(`/entity/entity/bulk-supplier-upload`, { ...params });
const sendAlertOnAccountSearch = params => client.post(`/entity/entity/send-alert-on-account-search`, { ...params });
const getRoles = params => client.post(`/entity/entity/get-roles`, { ...params });
const searchIntroducerContacts = (params, status) => client.post(`/entity/entity/search-introducer-contacts`, { ...params, status });
const notifyAdministrator = (params) => client.post(`/entity/entity/notify-admin`, { ...params });
const changeFacilities = (params) => client.post(`/entity/entity/update-facility-type`, { ...params });
const updateSecondaryProfile = (params) => client.post(`/entity/entity/update-secondary-profile`, { ...params });
const fetchEntityPrimaryContact = (params) => client.post(`/entity/entity/fetch-entity-primary-contact`, { ...params });

const validateSecondaryAccounts = params => client.post(`/entity/entity/validate-secondary-accounts-sheet`, { ...params });
const uploadSecondaryAccounts = params => client.post(`/entity/entity/upload-secondary-accounts-sheet`, { ...params });
const submitLimitRequest = params => client.post(`/entity/entity/submit-limit-request`, { ...params });

//service to fetch user connections
const GetUserNetwork = params => client.post(`/entity/entity/get-user-network`, { ...params });

// Service to fetch Entity heirarchy
const GetUserHeirarchy = params => client.post(`/entity/entity/get-user-heirarchy`, { ...params });

// Fetch client bank details
const FetchClientBankDetails = params => client.post(`/entity/entity/fetch-client-bank-details`, { ...params });

//service to fetch client bank details of all products
const FetchAllproductBankdetail = params => client.post(`/entity/entity/fetch-allproduct-bankdetail`, { ...params });

//Service to add edit bp facility nickname
const AddBpFacilityNickname = params => client.post(`/entity/entity/add-bp-facility-nickname`, { ...params });

// service to add client bank details
const AddClientBankDetail = params => client.post(`/entity/entity/add-client-bank-detail`, { ...params });

// Service to verify client bank account
const VerifyClientBankDetail = params => client.post(`/entity/entity/verify-client-bank-detail`, { ...params });

// get bank account updated history
const fetchBankAccountHistory = params => client.post(`/entity/entity/get-bank-history`, { ...params });

// Api to update entity status
const UpdateEntityStatus = params => client.post(`/entity/entity/update-entity-status`, { ...params });
const fetchTemplates = params => client.post(`/api/view-templates/fetch-templates`, { ...params });

// Service to fetch enabled products of a customer/supplier
const GetEnabledProductList = params => client.post(`/entity/entity/get-enabled-product-list`, { ...params});

// Service to fetch introducers by id
const fetchIntroducers = params => client.post(`/entity/entity/get-introducers-by-id`, { ...params});
const fetchProspectsIntroducers = params => client.post(`/prospect/prospects/get-introducers-by-id`, { ...params});
const fetchCombainedProspectsIntroducers = params => client.post(`/entity/entity/get-introducers-prospects`, { ...params});


// Service to upload business partner logo
const uploadBPLogo = params => client.post(`/entity/entity/upload-logo`, { ...params });
const unlinkBPLogo = params => client.post(`/entity/entity/unlink-logo`, { ...params });

// Service to fetch secondary accounts which are in draft state
const GetDraftSecondaryAccount = params => client.post(`/entity/entity/get-draft-secondary-account`, { ...params});

// service to add new secondary account from client side -- Account either draft or mapped
const AddClientSecondary = params => client.post(`/entity/entity/add-client-secondary`, { ...params});

// service to bulkupload secondary account from client side -- Account either draft or mapped
const ClientSecondaryBulkupload = params => client.post(`/entity/entity/client-secondary-bulkupload`, { ...params});

// service to assign facility to a secondary account from client
const DraftAssignFacility = params => client.post(`/entity/entity/draft-assign-facility`, { ...params});
const getTeamMemberById = params => client.post(`/entity/entity/get-team-member-by-id`, { ...params});

// Api to update entity details 
const UpdateEntityDetails = params => client.post(`/entity/entity/update-entity-details`, { ...params });

// Api to delete entity account
const deleteEntityAccount = params => client.post(`/entity/entity/delete-entity-account`, { ...params });

//Api to validate selected entities to delete
const validateEntityToDelete = params => client.post(`/entity/entity/validate-entity-to-delete`, { ...params });

//Api to hard delete selected entities 
const hardEntityDelete = params => client.post(`/entity/entity/hard-delete-entity-account`, { ...params });


//App Settings 
const fetchAppSettings = params => client.post(`/entity/entity/fetch-app-settings`, { ...params });
const saveAppSettings = params => client.post(`/entity/entity/save-app-settings`, { ...params });
//End App Settings 

const deleteDebtorMapping = params => client.post(`/entity/entity/delete-client-debtor-mapping`, { ...params });
//Api to bulk delete debtor mapping
const BulkDeleteDebtorMapping = params => client.post(`/entity/entity/bulk-delete-debtor-mapping`, { ...params });

const updateDebtorMapping = params => client.post(`/entity/entity/update-client-debtor-mapping`, { ...params });
// Api to fetch Bp account summary - count of active inactive primary account and draft account
const bpAccountSummary = params => client.post(`/entity/entity/bp-account-summary`, { ...params });

// Api to unlinked assigned facility
const UnlinkAssignedFacility = params => client.post(`/entity/entity/unlink-assigned-facility`, { ...params });

// Api to show product and interections
const GetProductsInteraction = params => client.post(`/entity/entity/get-products-interaction`, { ...params }); 

const UpdateInteraction = params => client.post(`/entity/entity/update-interaction`, { ...params }); 

// Api to get count of alternative field values
const GetAlternativeFieldCount= params => client.post(`/entity/entity/get-alternative-field-count`, { ...params }); 

// Api to add /view alternative field values
const AddAlternativeField= params => client.post(`/entity/entity/add-alternative-field`, { ...params }); 

// Api to list alternative field values
const AlternativeFieldList = params => client.post(`/entity/entity/alternative-field-list`, { ...params }); 

const DeleteAlternativeField  = params => client.post(`/entity/entity/delete-alternative-field`, { ...params }); 

const updateIfFacilityLimit = params => client.post(`/entity/entity/create-defaults`, { ...params }); 

const getFacilitySummary = params => client.post(`/entity/entity/facility-summary`, { ...params }); 

const FetchProfileBankDetail = params => client.post(`/entity/entity/fetch-profile-bank-detail`, { ...params }); 

const AddProfileBankDetail = params => client.post(`/entity/entity/add-profile-bank-detail`, { ...params }); 
const FetchBulkUploadAttachment = params => client.post(`/entity/entity/fetch-upload-attachment`, { ...params });  

//Api to fetch contact details of an entity
const GetEntityContactDetails = params => client.post(`/entity/entity/get-entity-contact-details`, { ...params });  

//Api to update contact details of an entity
const UpdateContactDetails = params => client.post(`/entity/entity/update-contact-details`, { ...params });  

//Api to delete a partner from list
const DeletePartner = params => client.post(`/entity/business-partner/delete`, { ...params });  

//Api to update facility_start_date
const facilityStartDate = params => client.post(`/entity/entity-category-mapping/update-facility-settings`, { ...params });

//Api to fetch facility_start_date
const FetchfacilitySettings = params => client.post(`/entity/entity-category-mapping/fetch-facility-settings`, { ...params });
//Api to fetch bp default settings 
const getBpDefaults = params => client.post(`/entity/entity/get-bp-settings`, { ...params });


//Api to fetch IFAccountsData
const AccountsData = params => client.post(`/invtransactions/widgets/dashboard-accounts-data`, { ...params });

//Api to fetch DFAccountsData
const DFAccountsData = params => client.post(`/dftransactions/transactions/wob-account-data`, { ...params });

//Api to fetch SCFAccountsData
const SCFAccountsData = params => client.post(`/entity/entity/scf-account-data`, { ...params });

//Api to fetch LoanAccountsData
const LoanAccountsData = params => client.post(`/loans/loan/bl-account-data`, { ...params });

// Api to get details of an entity contact member
const GetContactDetail  = params => client.post(`/entity/entity/get-entity-contact`, { ...params });

//Api to validate bulk upload introducer sheet
const validateIntroducerSheet  = params => client.post(`/entity/introducer/validate-excel-sheet`, { ...params });

//Api to  bulk upload introducer sheet
const IntroducerBulkUpload  = params => client.post(`/entity/introducer/bulk-upload`, { ...params });

// Api to list teamMembers with cnnected bp of an introducer
const GetContactList  = params => client.post(`/entity/introducer/contact-list`, { ...params });

// Api to list mapped bps under an introducer
const GetIntroducerMappedBp  = params => client.post(`/entity/introducer/get-introducer-mapped-bp`, { ...params });

// Api to check all activities associated with a bp
const GetBpActivities  = params => client.post(`/entity/business-partner/list-bp-activities`, { ...params });

// Api to change owner of an account
const ChangeOwner  = params => client.post(`/entity/business-partner/change-owner`, { ...params });

// Api to fetch eligibility of a contact to send invitation
const VerifyInvitationEligibility = params => client.post(`/entity/entity/verify-invitation-eligibility`, { ...params });

// Api to convert a draft account to secondary
const ConvertToSecondary = params => client.post(`/entity/entity/convert-to-secondary`, { ...params });

// Api to change entity verification status
const UpdateVerificationStatus = params => client.post(`/entity/entity/update-verification-status`, { ...params });

// Api to fetch and save notes from hubspot
const SyncNotes = params => client.post(`/entity/entity/sync-notes`, { ...params });

// Api to validate introducer migration sheet
const validateIntroducerMigrationSheet = params => client.post(`/entity/introducer/validate-introducer-migration-sheet`, { ...params });

//migrate introducers from an excel sheet 
const MigrateIntroducer  = params => client.post(`/entity/introducer/migrate-introducer`, { ...params });

// Api to change introducer of an account
const ChangeIntroducer  = params => client.post(`/entity/introducer/change-introducer`, { ...params });

// Api to fetch clients based on introducer
const GetIntroducerClients  = params => client.post(`/entity/introducer/list-introducer-clients`, { ...params });

// Api to change owner for introducer
const ChangeOwnerForIntroducer  = params => client.post(`/entity/entity/change-owner-for-introducer`, { ...params });
const descUpload  = params => client.post(`/entity/entity/entity-field-bulk-upload`, { ...params });

const listAllMembers = params =>client.post(`/entity/entity/list-all-team-members`, { ...params });
const getMemberByContactId = params =>client.post(`/entity/entity/get-team-member-by-contact-id`, { ...params });
const updateEntityContact = params =>client.post(`/entity/entity/update-entity-contact`, { ...params });
const searchTeamMembers = params => client.post(`/entity/entity/search-team-members`, { ...params });

const updateEntityCrManager = params => client.post(`/entity/entity/update-entity-cr-manager`, { ...params });

export default {
  createBusinessPartner,
  createEntity,
  createGuarantor,
  getEntitiesList,
  getEntityDetails,
  getEntityContactsList,
  getEntityParentContactsList,
  //deleteEntity,
  getBusinessPartnersList,
  getTabsList,
  getEntityGuarantorsList,
  getGuarantorsList,
  getDebtorsList,
  mapGuarantor,
  mapDebtor,
  deleteEntityGuarantor,
  deleteEntityDebtor,
  createEntityContact,
  deleteEntityContact,
  fetchKeywordSuggestions,
  getEntityNotesList,
  createEntityNote,
  getCountriesTabList,
  handleContactCheckboxChange,
  createDefaults,
  getEntityDefaults,
  assignParentContact,
  copyEntity,
  GetEntityUsersByCategory,
  fetchInvestmentPartners,
  getRecivables,
  getIntroductions,
  getPayables,
  getEntitiesInvitataionList,
  createUserInvitation,
  fetchKeywordSuggestionsBp,
  getClientInvitationMessage,
  sendClientInvitationMail,
  fetchNoteList,
  fetchNoteWordSuggestions,
  getEntityDetailsProfile,
  getEntityDebtorsList,
  getEntityDebtorsKeywordSuggestions,
  deactivateInvitation,
  getTemplatePreview,
  getCountryOfficesList,
  getUsersToBeNotified,
  sendPaymentNotifications,
  fetchCompaniesListByEntityName,
  fetchCompanyDetailsByNzbn,
  updateProfileInfo,
  updateReferralPartner,
  saveEntityCoreFields,
  getEntityCategories,
  listEntities,
  searchEntities,
  assignRoles,
  bulkUpdate,
  //deleteEntity,
  deleteBulkEntities,
  searchByAbn,
  saveEntityCategoryBp,
  validateExcelSheet,
  bulkUpload,
  listPartners,
  searchPartners,
  fetchDebtorSuggestions,
  entityProducts,
  saveClientOrganizatioinDetails,
  fetchClientOrgDetails,
  entityLoginHistory,
  getInviteeInfoByToken,
  verifyContactEmailAgainstToken,
  getOrganisationsListByContactEmail,
  bulkChangeOwnership,
  getSecondaryProfile,
  listIntroducerContacts,
  bulkBpIntrdcrContactMapping,
  mappedIntroducers,
  saveDefaultSettings,
  fetchDefaultSettings,
  getBpIntroducerCount,
  fetchSecondaryMappedUsers,
  activateInvFinance,
  quickSearch,
  saveEntityCoreFieldsPublic,
  getEntityCount,
  getIfAccountsCount,
  getIfDisbursementsCount,
  listEntityContacts,
  sendAlert,
  fetchCountryCode,
  deactivateEntityContact,
  bulkSupplierValidation,
  bulkSupplierUpload,
  sendAlertOnAccountSearch,
  getRoles,
  searchIntroducerContacts,
  notifyAdministrator,
  changeFacilities,
  updateSecondaryProfile,
  fetchEntityPrimaryContact,
  validateSecondaryAccounts,
  uploadSecondaryAccounts,
  submitLimitRequest,
  GetUserHeirarchy,
  FetchClientBankDetails,
  FetchAllproductBankdetail,
  AddClientBankDetail,
  fetchBankAccountHistory,
  VerifyClientBankDetail,
  GetUserNetwork,
  UpdateEntityStatus,
  fetchTemplates,
  GetEnabledProductList,
  fetchIntroducers,
  fetchProspectsIntroducers,
  fetchCombainedProspectsIntroducers,
  uploadBPLogo,
  unlinkBPLogo,
  GetDraftSecondaryAccount,
  AddClientSecondary,
  ClientSecondaryBulkupload,
  DraftAssignFacility,
  getTeamMemberById,
  UpdateEntityDetails,
  deleteEntityAccount,
  validateEntityToDelete,
  fetchAppSettings,
  saveAppSettings,
  deleteDebtorMapping,
  BulkDeleteDebtorMapping,
  bpAccountSummary,
  UnlinkAssignedFacility,
  updateDebtorMapping,
  GetProductsInteraction,
  UpdateInteraction,
  GetAlternativeFieldCount,
  AddAlternativeField,
  AlternativeFieldList,
  DeleteAlternativeField,
  updateIfFacilityLimit,
  AddBpFacilityNickname,
  getFacilitySummary,
  FetchProfileBankDetail,
  AddProfileBankDetail,
  FetchBulkUploadAttachment,
  GetEntityContactDetails,
  UpdateContactDetails,
  DeletePartner,
  facilityStartDate,
  FetchfacilitySettings,
  getBpDefaults,
  AccountsData,
  DFAccountsData,
  SCFAccountsData,
  LoanAccountsData,
  GetContactDetail,
  validateIntroducerSheet,
  IntroducerBulkUpload,
  GetContactList,
  GetIntroducerMappedBp,
  GetBpActivities,
  ChangeOwner,
  VerifyInvitationEligibility,
  ConvertToSecondary,
  UpdateVerificationStatus,
  SyncNotes,
  hardEntityDelete,
  validateIntroducerMigrationSheet,
  MigrateIntroducer,
  ChangeIntroducer,
  GetIntroducerClients,
  ChangeOwnerForIntroducer,
  descUpload,
  listAllMembers,
  getMemberByContactId,
  updateEntityContact,
  searchTeamMembers,
  updateEntityCrManager,
  getCrManagersList,

};
