import React from "react";
import { smoothlyMenu } from "../Helpers";
import $ from "jquery";
import { Link } from "react-router";
import dashboard from "../../../../services/dashboardServices";
import messageService from "../../../../services/newsletterServices";
import configService from "../../../../services/configService";
import auth from "../../../../services/authService";
import "../../../../styles/style.css";
import "../../../../styles/common.css";
import NotificationContext from "../../../../context/notificationContext";
import StartTour from "../../../tour/startTour";
import * as moment from "moment";
import { ShowCustomAlert } from "../../../../utils/alertUtils";

class TopHeader extends React.Component {
  static contextType = NotificationContext;

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
  }

  toggleNavigation(e) {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }
  state = {
    notifications: 1,
    notification_title: "",
    ajaxRequest: false,
    rows: [],
    welcomeMessages: [
      "Welcome",
      "Hola!",
      "G'day",
      "Howdy-doody!",
      "Howdy",
      "Howdy",
      "Hey there",
      "Peek-a-boo!",
      "I come in peace!",
      "Hi. Welcome back",
    ],
    selectedWelcome: "Welcome",
    displayCustomBanner: false,
    notificationInfo: null,
  };

  componentDidMount() {
    this.fetchnotifications();
    this.fetchTopAlert();
    let randomVal = Math.floor(Math.random() * 7);
    this.setState({ selectedWelcome: this.state.welcomeMessages[randomVal] });

    const script1 = document.createElement("script");
    script1.innerHTML =
      "window.intercomSettings = { api_base: 'https://api-iam.intercom.io', app_id: 'i4333cxx', loggedUser: 'fifoCentralUser'};";
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML =
      "(function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/i4333cxx';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();";
    document.body.appendChild(script2);
  }

  playAudio() {
    const audioPromise = this.audio.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then((_) => {
          // autoplay started
        })
        .catch((err) => {
          // catch dom exception
          console.info(err);
        });
    }
  }

  fetchnotifications = async (guid = null) => {
    const user = auth.getCurrentUser();
    if (user) {
      // Finds the difference in minutes between the last logged in time and the current time (UTC)
      let playNotification = true;

      if (user.last_logged_in_time) {
        let currentUTC = new Date(new Date().toUTCString().slice(0, -3));
        let now = moment(currentUTC);
        let then = moment(user.last_logged_in_time);
        let minutes = now.diff(then, "minutes");

        if (minutes < 15) playNotification = false;
      }
      // ----------------------------------------- //

      this.setState({ ajaxRequest: true });
      this.context.setNotificationLoader(true);
      const rows = await dashboard.getNotifications(user.entity_id);
      this.context.setNotificationLoader(false);

      if (rows) {
        this.context.setRows(rows);
        let notificationCount = rows.notifications.length;

        let audioFile = null;

        if (notificationCount === 1) audioFile = "1_unread_notification";
        else if (notificationCount === 2) audioFile = "2_unread_notifications";
        else if (notificationCount >= 3)
          //&& notificationCount <= 9)
          audioFile = "a_few_unread_notifications";
        //else if(notificationCount >= 10)
        //  audioFile = 'over_10_notifications';

        if (audioFile && !this.context.notificationPlayed && playNotification) {
          this.audio = new Audio(`audios/${audioFile}.m4a`);
          this.audio.load();
          this.playAudio();
          this.context.setNotificationPlayed(true);
        }
      }
    }
  };

  fetchTopAlert = async () => {
    try {
      const user = auth.getCurrentUser();

      if (user) {
        const { data } = await configService.getTopHeaderAlertCheck(
          user.country_id,
          "active"
        );
        this.setState({ topAlert: data.rows });

        if (
          data.rows?.[0]?.show_popup_in_central === "1" &&
          auth.getLoginMinutes() === 0
        ) {
          this.setState({
            displayCustomBanner: true,
            notificationInfo: data.rows[0],
          });
        }
      }
    } catch (error) {
      console.error("Error fetching top alert:", error);
    }
  };

  handleRead = async (notification_guid) => {
    try {
      const { success } = await dashboard.readNotification(notification_guid);
      if (success) {
        this.fetchnotifications();
      }
    } catch (error) {}
  };

  handleMsgClick = async (newsletter_guid) => {
    const { data } = await messageService.changeMessageStatus({
      messages: [{ newsletter_guid }],
    });
    if (data.success) {
      this.fetchnotifications();
    }
  };

  handleTourClick = (e) => {
    e.preventDefault();
    this.childRef.current.handleClick(e);
  };

  render() {
    const user = auth.getCurrentUser();

    return (
      <NotificationContext.Consumer>
        {(context) => (
          <React.Fragment>
            {this.state.displayCustomBanner && (
              <ShowCustomAlert
                alertData={this.state.notificationInfo}
                userType="central"
                onClose={() => {
                  this.setState({
                    displayCustomBanner: false,
                    notificationInfo: null,
                  });
                }}
              />
            )}

            <div className="row border-bottom">
              <nav
                className="navbar navbar-static-top align-items-center"
                role="navigation"
                style={{ marginBottom: 0 }}
              >
                <div className="navbar-header d-flex align-items-center">
                  <a
                    className="navbar-minimalize minimalize-styl-2"
                    onClick={this.toggleNavigation}
                  >
                    <i className="fa fa-bars"></i>{" "}
                  </a>
                  <span class="m-l-sm text-muted welcome-message">
                    {this.state.selectedWelcome}{" "}
                    <strong className="font-bold">
                      {user && user.contact_name}
                    </strong>
                  </span>
                </div>

                {process.env.REACT_APP_ENV === "qa" && (
                  <div className="ml-2 mt-2 alert alert-danger">
                    <strong>
                      {" "}
                      <i className="fa fa-warning mr-2"></i>
                      {"This is a preproduction build"}
                    </strong>
                  </div>
                )}

                <ul className="nav navbar-right">
                  <li>
                    <Link
                      className="action-icons"
                      title="Settings"
                      to={{
                        pathname: "/update-profile",
                      }}
                    >
                      <i class="fa fa-cog"></i>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="action-icons"
                      title="Help"
                      onClick={(e) => this.handleTourClick(e)}
                    >
                      <i class="fa fa-support"></i>
                    </Link>
                  </li>

                  {/*------------- Notifications List ------------- */}
                  <li class="dropdown">
                    <a
                      class="dropdown-toggle count-info"
                      data-toggle="dropdown"
                      href="#"
                    >
                      <i class="fa fa-bell mb-2"></i>
                      {context.notifications.length > 0 && (
                        <span class="label label-primary">
                          {context.notifications.length}
                        </span>
                      )}
                    </a>

                    <ul class="dropdown-menu mx-auto ">
                      <li>
                        {context.notifications
                          .slice(0, 4)
                          .map((notification) => {
                            return (
                              <div className="d-flex align-items-center  p-3">
                                <div className="d-flex flex-column">
                                  <Link
                                    to={{
                                      pathname: "/notification/view",
                                      state: notification,
                                    }}
                                    className="action-icons "
                                    onClick={(e) => {
                                      this.handleRead(
                                        notification.notification_guid
                                      );
                                    }}
                                  >
                                    <span>
                                      <strong>{notification.title}</strong>
                                    </span>
                                  </Link>
                                  <span className="text-xs opacity-75">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: notification.notification,
                                      }}
                                    ></div>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </li>
                      <li class="dropdown-divider"></li>
                      <li>
                        <div class="text-center link-block">
                          <Link
                            to="/notifications"
                            class="dropdown-item text-lg"
                          >
                            <strong>View Notification Inbox</strong>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </li>
                  {/*----------------------------------------------- */}

                  {/*----------------- Messages List --------------- */}
                  <li class="dropdown">
                    <a
                      class="dropdown-toggle count-info"
                      data-toggle="dropdown"
                      href="#"
                    >
                      <i class="fa fa-envelope mb-2"></i>
                      {context.messages.length > 0 && (
                        <span class="label label-primary">
                          {context.messages.length}
                        </span>
                      )}
                    </a>

                    <ul class="dropdown-menu  ">
                      {context.messages.map((message) => {
                        return (
                          <li
                            className="action-icons  p-4"
                            onClick={(e) => {
                              this.handleMsgClick(message.newsletter_guid);
                            }}
                          >
                            <div>
                              <i class="fa fa-envelope fa-fw"></i>
                              <strong>{message.title}</strong>
                            </div>
                            <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message.content,
                                }}
                              ></div>
                            </div>
                            <li class="divider"></li>
                          </li>
                        );
                      })}

                      <li class="dropdown-divider"></li>
                      <li>
                        <div class="text-center link-block">
                          {context.messages.length > 0 ? (
                            <a href="/messages" class="dropdown-item text-lg">
                              <strong>See All Messages</strong>
                            </a>
                          ) : (
                            <>
                              <strong>No new messages</strong>
                              <Link
                                class="dropdown-item text-lg"
                                to="/messages"
                              >
                                <strong>Create Message</strong>
                              </Link>
                            </>
                          )}
                        </div>
                      </li>
                    </ul>
                  </li>
                  {/*----------------------------------------------- */}

                  <li>
                    <Link
                      className="action-icons"
                      onClick={(e) =>
                        this.context.setProspectExpiryPrompt(true)
                      }
                      to="/logout"
                    >
                      <i className="fa fa-sign-out"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            {/*-------- tour ----------- */}
            <StartTour ref={this.childRef} location={this.props.location} />
            {/*-------- tour ----------- */}
          </React.Fragment>
        )}
      </NotificationContext.Consumer>
    );
  }
}

export default TopHeader;
