import { useState, useCallback } from "react";
import { toast } from "react-toastify";

/**
 * Custom hook to handle API requests with Axios.
 * 
 * The `backwardCompatible` flag was introduced to allow the hook 
 * to support both the old implementation (for legacy components) 
 * and a new version with enhanced features (for newer components).
 * 
 * When `backwardCompatible` is true (default), the hook returns data 
 * in the old format and behaves as expected by existing components.
 * When `backwardCompatible` is false, the hook returns a more structured 
 * response with `data`, `statusCode`, and `error`, giving more flexibility 
 * for newer components.
 * 
 * @param {function} apiFunc - The API function to call.
 * @param {boolean} backwardCompatible - Whether to use the old implementation (default: true).
 * 
 * @returns {object} Contains `data`, `error`, `statusCode`, `loading`, `request`, and `setData`.
 */

const useApi = (apiFunc, backwardCompatible = true) => {
    const [data, setData] = useState(backwardCompatible ? [] : null);  // Set initial value based on compatibility mode
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(null);
    const [loading, setLoading] = useState(false);

    const defaultErrorMsg = 'Sorry, something went wrong while processing your request. Please contact the administrator if the issue persists.';

    const emptyResponse = backwardCompatible
        ? { data: { rows: [] } }  // Old version expects an empty rows array
        : { data: null };

    const request = useCallback(async (...args) => {
        setLoading(true);
        try {
            const response = await apiFunc(...args);
            setData(response.data);
            setStatusCode(response.status);
            setError(null);
            setLoading(false);

            // For backward compatibility, just return the response data
            return backwardCompatible
                ? response
                : { data: response.data, statusCode: response.status, error: null };
        } catch (error) {
            // Handle cases where error response or its nested properties may be undefined
            const status = error?.response?.status || null;
            let errorMessage = defaultErrorMsg; // Default error message

            // Check if error message is a stringified JSON
            const errorData = error?.response?.data?.message;
            if (errorData) {
                try {
                    // Try parsing the error message
                    const parsedMessage = JSON.parse(errorData);
                    if (parsedMessage?.type === 'custom') {
                        errorMessage = parsedMessage.message; // Custom error message
                    }
                } catch (e) {
                    // If parsing fails, use the original error message or fallback to defaults
                    errorMessage = errorData ||
                        (status === 401 || status === 403
                            ? 'Unauthorized access. Please log in again.'
                            : defaultErrorMsg);
                }
            }

            // Set error and status in state
            setError(backwardCompatible ? true : errorMessage); // Set error to true for backward compatibility
            setStatusCode(status);
            setLoading(false);

            // Return based on compatibility mode
            return backwardCompatible
                ? emptyResponse
                : { data: null, statusCode: status, error: errorMessage };
        }
    }, [apiFunc, backwardCompatible]);

    return { data, error, statusCode, loading, request, setData };
};

export default useApi;